/*!
 * Bootstrap v2.1.0
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
@font-face {
  font-family: RobotoRegular;
  src: url("Roboto-Regular-webfont.85c57aa4.eot");
  src: url("Roboto-Regular-webfont.85c57aa4.eot#iefix") format("embedded-opentype"), url("Roboto-Regular-webfont.f064fc74.woff") format("woff"), url("Roboto-Regular-webfont.7932fca5.ttf") format("truetype"), url("Roboto-Regular-webfont.762f2bec.svg#RobotoRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoItalic;
  src: url("Roboto-Italic-webfont.07c1bda7.eot");
  src: url("Roboto-Italic-webfont.07c1bda7.eot#iefix") format("embedded-opentype"), url("Roboto-Italic-webfont.67f828b8.woff") format("woff"), url("Roboto-Italic-webfont.6f2dae54.ttf") format("truetype"), url("Roboto-Italic-webfont.d615fe60.svg#RobotoItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBold;
  src: url("Roboto-Bold-webfont.8e85d962.eot");
  src: url("Roboto-Bold-webfont.8e85d962.eot#iefix") format("embedded-opentype"), url("Roboto-Bold-webfont.42323101.woff") format("woff"), url("Roboto-Bold-webfont.d53970a3.ttf") format("truetype"), url("Roboto-Bold-webfont.803bca04.svg#RobotoBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBoldItalic;
  src: url("Roboto-BoldItalic-webfont.91f928cb.eot");
  src: url("Roboto-BoldItalic-webfont.91f928cb.eot#iefix") format("embedded-opentype"), url("Roboto-BoldItalic-webfont.585f2130.woff") format("woff"), url("Roboto-BoldItalic-webfont.ed831684.ttf") format("truetype"), url("Roboto-BoldItalic-webfont.16ab28d0.svg#RobotoBoldItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoCondensed;
  src: url("Roboto-Condensed-webfont.405fa23c.eot");
  src: url("Roboto-Condensed-webfont.405fa23c.eot#iefix") format("embedded-opentype"), url("Roboto-Condensed-webfont.3e6302f3.woff") format("woff"), url("Roboto-Condensed-webfont.b1e579a8.ttf") format("truetype"), url("Roboto-Condensed-webfont.4a5ccfea.svg#RobotoCondensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoCondensedItalic;
  src: url("Roboto-CondensedItalic-webfont.40e274ea.eot");
  src: url("Roboto-CondensedItalic-webfont.40e274ea.eot#iefix") format("embedded-opentype"), url("Roboto-CondensedItalic-webfont.34c62e4f.woff") format("woff"), url("Roboto-CondensedItalic-webfont.689558c2.ttf") format("truetype"), url("Roboto-CondensedItalic-webfont.63814e8e.svg#RobotoCondensedItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBoldCondensed;
  src: url("Roboto-BoldCondensed-webfont.b96b045c.eot");
  src: url("Roboto-BoldCondensed-webfont.b96b045c.eot#iefix") format("embedded-opentype"), url("Roboto-BoldCondensed-webfont.46b67c4d.woff") format("woff"), url("Roboto-BoldCondensed-webfont.1ca01be4.ttf") format("truetype"), url("Roboto-BoldCondensed-webfont.cb4c3987.svg#RobotoBoldCondensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBoldCondensedItalic;
  src: url("Roboto-BoldCondensedItalic-webfont.2f424476.eot");
  src: url("Roboto-BoldCondensedItalic-webfont.2f424476.eot#iefix") format("embedded-opentype"), url("Roboto-BoldCondensedItalic-webfont.c31046d3.woff") format("woff"), url("Roboto-BoldCondensedItalic-webfont.8b4f6c5a.ttf") format("truetype"), url("Roboto-BoldCondensedItalic-webfont.a1890863.svg#RobotoBoldCondensedItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoThin;
  src: url("Roboto-Thin-webfont.3a906cee.eot");
  src: url("Roboto-Thin-webfont.3a906cee.eot#iefix") format("embedded-opentype"), url("Roboto-Thin-webfont.be2d0fda.woff") format("woff"), url("Roboto-Thin-webfont.c3b5d4a8.ttf") format("truetype"), url("Roboto-Thin-webfont.a81f2f66.svg#RobotoThin") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoThinItalic;
  src: url("Roboto-ThinItalic-webfont.5581fecf.eot");
  src: url("Roboto-ThinItalic-webfont.5581fecf.eot#iefix") format("embedded-opentype"), url("Roboto-ThinItalic-webfont.0947f4f6.woff") format("woff"), url("Roboto-ThinItalic-webfont.35a316d8.ttf") format("truetype"), url("Roboto-ThinItalic-webfont.ccc2f76b.svg#RobotoThinItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoLight;
  src: url("Roboto-Light-webfont.e6e42f9a.eot");
  src: url("Roboto-Light-webfont.e6e42f9a.eot#iefix") format("embedded-opentype"), url("Roboto-Light-webfont.2a7d08c6.woff") format("woff"), url("Roboto-Light-webfont.b921fe78.ttf") format("truetype"), url("Roboto-Light-webfont.c69f3c90.svg#RobotoLight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoLightItalic;
  src: url("Roboto-LightItalic-webfont.9f6d961f.eot");
  src: url("Roboto-LightItalic-webfont.9f6d961f.eot#iefix") format("embedded-opentype"), url("Roboto-LightItalic-webfont.ed84f026.woff") format("woff"), url("Roboto-LightItalic-webfont.1d200dda.ttf") format("truetype"), url("Roboto-LightItalic-webfont.9c1dfca5.svg#RobotoLightItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoMedium;
  src: url("Roboto-Medium-webfont.82dc1444.eot");
  src: url("Roboto-Medium-webfont.82dc1444.eot#iefix") format("embedded-opentype"), url("Roboto-Medium-webfont.968c9d5b.woff") format("woff"), url("Roboto-Medium-webfont.a11a8fcd.ttf") format("truetype"), url("Roboto-Medium-webfont.79dbf278.svg#RobotoMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoMediumItalic;
  src: url("Roboto-MediumItalic-webfont.513743a7.eot");
  src: url("Roboto-MediumItalic-webfont.513743a7.eot#iefix") format("embedded-opentype"), url("Roboto-MediumItalic-webfont.3e9de854.woff") format("woff"), url("Roboto-MediumItalic-webfont.ea182fa5.ttf") format("truetype"), url("Roboto-MediumItalic-webfont.93d0061c.svg#RobotoMediumItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBlack;
  src: url("Roboto-Black-webfont.5e8c294e.eot");
  src: url("Roboto-Black-webfont.5e8c294e.eot#iefix") format("embedded-opentype"), url("Roboto-Black-webfont.19ae1dc1.woff") format("woff"), url("Roboto-Black-webfont.463e6f00.ttf") format("truetype"), url("Roboto-Black-webfont.21a958d3.svg#RobotoBlack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBlackItalic;
  src: url("Roboto-BlackItalic-webfont.4cf68173.eot");
  src: url("Roboto-BlackItalic-webfont.4cf68173.eot#iefix") format("embedded-opentype"), url("Roboto-BlackItalic-webfont.2c33cc4f.woff") format("woff"), url("Roboto-BlackItalic-webfont.bc284be2.ttf") format("truetype"), url("Roboto-BlackItalic-webfont.4d9ce8b3.svg#RobotoBlackItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: AndroidClock-LargeRegular;
  src: url("androidclock-webfont.aac9d899.eot");
  src: url("androidclock-webfont.aac9d899.eot#iefix") format("embedded-opentype"), url("androidclock-webfont.580837a6.woff") format("woff"), url("androidclock-webfont.96d416bb.ttf") format("truetype"), url("androidclock-webfont.4d4b8697.svg#AndroidClock-LargeRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: AndroidClock-LargeRegular;
  src: url("androidclock_highlight-webfont.b9369ebf.eot");
  src: url("androidclock_highlight-webfont.b9369ebf.eot#iefix") format("embedded-opentype"), url("androidclock_highlight-webfont.d9e7ee02.woff") format("woff"), url("androidclock_highlight-webfont.e47fd1ac.ttf") format("truetype"), url("androidclock_highlight-webfont.4d4b8697.svg#AndroidClock-LargeRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: AndroidClock-LargeRegular;
  src: url("androidclock_solid-webfont.aef9bee3.eot");
  src: url("androidclock_solid-webfont.aef9bee3.eot#iefix") format("embedded-opentype"), url("androidclock_solid-webfont.b68e5cc4.woff") format("woff"), url("androidclock_solid-webfont.0b5ec346.ttf") format("truetype"), url("androidclock_solid-webfont.4d4b8697.svg#AndroidClock-LargeRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.clearfix:before, .clearfix:after {
  content: "";
  line-height: 0;
  display: table;
}

.clearfix:after {
  clear: both;
}

.hide-text {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.input-block-level {
  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  display: block;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 100%;
}

a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

a:hover, a:active {
  outline: 0;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  border: 0;
  max-width: 100%;
  height: auto;
}

#map_canvas img {
  max-width: none;
}

button, input, select, textarea {
  vertical-align: middle;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

body {
  color: #333;
  background-color: #fff;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
}

a {
  color: #08c;
  text-decoration: none;
}

a:hover {
  color: #005580;
  text-decoration: underline;
}

.img-rounded {
  border-radius: 6px;
}

.img-polaroid {
  background-color: #fff;
  border: 1px solid #0003;
  padding: 4px;
  box-shadow: 0 1px 3px #0000001a;
}

.img-circle {
  border-radius: 500px;
}

.row {
  margin-left: -20px;
}

.row:before, .row:after {
  content: "";
  line-height: 0;
  display: table;
}

.row:after {
  clear: both;
}

[class*="span"] {
  float: left;
  margin-left: 20px;
}

.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container, .span12 {
  width: 940px;
}

.span11 {
  width: 860px;
}

.span10 {
  width: 780px;
}

.span9 {
  width: 700px;
}

.span8 {
  width: 620px;
}

.span7 {
  width: 540px;
}

.span6 {
  width: 460px;
}

.span5 {
  width: 380px;
}

.span4 {
  width: 300px;
}

.span3 {
  width: 220px;
}

.span2 {
  width: 140px;
}

.span1 {
  width: 60px;
}

.offset12 {
  margin-left: 980px;
}

.offset11 {
  margin-left: 900px;
}

.offset10 {
  margin-left: 820px;
}

.offset9 {
  margin-left: 740px;
}

.offset8 {
  margin-left: 660px;
}

.offset7 {
  margin-left: 580px;
}

.offset6 {
  margin-left: 500px;
}

.offset5 {
  margin-left: 420px;
}

.offset4 {
  margin-left: 340px;
}

.offset3 {
  margin-left: 260px;
}

.offset2 {
  margin-left: 180px;
}

.offset1 {
  margin-left: 100px;
}

.row-fluid {
  width: 100%;
}

.row-fluid:before, .row-fluid:after {
  content: "";
  line-height: 0;
  display: table;
}

.row-fluid:after {
  clear: both;
}

.row-fluid [class*="span"] {
  box-sizing: border-box;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.12766%;
  display: block;
}

.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}

.row-fluid .span12 {
  width: 100%;
}

.row-fluid .span11 {
  width: 91.4894%;
}

.row-fluid .span10 {
  width: 82.9787%;
}

.row-fluid .span9 {
  width: 74.4681%;
}

.row-fluid .span8 {
  width: 65.9574%;
}

.row-fluid .span7 {
  width: 57.4468%;
}

.row-fluid .span6 {
  width: 48.9362%;
}

.row-fluid .span5 {
  width: 40.4255%;
}

.row-fluid .span4 {
  width: 31.9149%;
}

.row-fluid .span3 {
  width: 23.4043%;
}

.row-fluid .span2 {
  width: 14.8936%;
}

.row-fluid .span1 {
  width: 6.38298%;
}

.row-fluid .offset12 {
  margin-left: 104.255%;
}

.row-fluid .offset12:first-child {
  margin-left: 102.128%;
}

.row-fluid .offset11 {
  margin-left: 95.7447%;
}

.row-fluid .offset11:first-child {
  margin-left: 93.617%;
}

.row-fluid .offset10 {
  margin-left: 87.2341%;
}

.row-fluid .offset10:first-child {
  margin-left: 85.1064%;
}

.row-fluid .offset9 {
  margin-left: 78.7234%;
}

.row-fluid .offset9:first-child {
  margin-left: 76.5958%;
}

.row-fluid .offset8 {
  margin-left: 70.2128%;
}

.row-fluid .offset8:first-child {
  margin-left: 68.0851%;
}

.row-fluid .offset7 {
  margin-left: 61.7021%;
}

.row-fluid .offset7:first-child {
  margin-left: 59.5745%;
}

.row-fluid .offset6 {
  margin-left: 53.1915%;
}

.row-fluid .offset6:first-child {
  margin-left: 51.0638%;
}

.row-fluid .offset5 {
  margin-left: 44.6809%;
}

.row-fluid .offset5:first-child {
  margin-left: 42.5532%;
}

.row-fluid .offset4 {
  margin-left: 36.1702%;
}

.row-fluid .offset4:first-child {
  margin-left: 34.0426%;
}

.row-fluid .offset3 {
  margin-left: 27.6596%;
}

.row-fluid .offset3:first-child {
  margin-left: 25.5319%;
}

.row-fluid .offset2 {
  margin-left: 19.1489%;
}

.row-fluid .offset2:first-child {
  margin-left: 17.0213%;
}

.row-fluid .offset1 {
  margin-left: 10.6383%;
}

.row-fluid .offset1:first-child {
  margin-left: 8.51064%;
}

[class*="span"].hide, .row-fluid [class*="span"].hide {
  display: none;
}

[class*="span"].pull-right, .row-fluid [class*="span"].pull-right {
  float: right;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.container:before, .container:after {
  content: "";
  line-height: 0;
  display: table;
}

.container:after {
  clear: both;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid:before, .container-fluid:after {
  content: "";
  line-height: 0;
  display: table;
}

.container-fluid:after {
  clear: both;
}

p {
  margin: 0 0 9px;
}

.lead {
  margin-bottom: 18px;
  font-size: 20px;
  font-weight: 200;
  line-height: 27px;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

cite {
  font-style: normal;
}

.muted {
  color: #999;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  text-rendering: optimizelegibility;
  margin: 9px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 1;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #999;
  font-weight: normal;
  line-height: 1;
}

h1 {
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-size: 30px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 40px;
}

h4 {
  font-size: 18px;
  line-height: 20px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
}

h6 {
  font-size: 12px;
  line-height: 20px;
}

h1 small {
  font-size: 24px;
}

h2 small {
  font-size: 18px;
}

h3 small, h4 small {
  font-size: 14px;
}

.page-header {
  border-bottom: 1px solid #eee;
  margin: 18px 0 27px;
  padding-bottom: 8px;
}

ul, ol {
  margin: 0 0 9px 25px;
  padding: 0;
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

li {
  line-height: 18px;
}

ul.unstyled, ol.unstyled {
  margin-left: 0;
  list-style: none;
}

dl {
  margin-bottom: 18px;
}

dt, dd {
  line-height: 18px;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 9px;
}

.dl-horizontal dt {
  float: left;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
}

.dl-horizontal dd {
  margin-left: 130px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
  margin: 18px 0;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #999;
}

abbr.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #eee;
  margin: 0 0 18px;
  padding: 0 0 0 15px;
}

blockquote p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.5px;
}

blockquote small {
  color: #999;
  line-height: 18px;
  display: block;
}

blockquote small:before {
  content: "— ";
}

blockquote.pull-right {
  float: right;
  border-left: 0;
  border-right: 5px solid #eee;
  padding-left: 0;
  padding-right: 15px;
}

blockquote.pull-right p, blockquote.pull-right small {
  text-align: right;
}

blockquote.pull-right small:before {
  content: "";
}

blockquote.pull-right small:after {
  content: " —";
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

address {
  margin-bottom: 18px;
  font-style: normal;
  line-height: 18px;
  display: block;
}

code, pre {
  color: #333;
  border-radius: 3px;
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, Courier New, monospace;
  font-size: 12px;
}

code {
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  padding: 2px 4px;
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #00000026;
  border-radius: 4px;
  margin: 0 0 9px;
  padding: 8.5px;
  font-size: 13px;
  line-height: 18px;
  display: block;
}

pre.prettyprint {
  margin-bottom: 18px;
}

pre code {
  color: inherit;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.label, .badge {
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px #00000040;
  background-color: #999;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
}

.label {
  border-radius: 3px;
  padding: 1px 4px 2px;
}

.badge {
  border-radius: 9px;
  padding: 1px 9px 2px;
}

a.label:hover, a.badge:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.label-important, .badge-important {
  background-color: #b94a48;
}

.label-important[href], .badge-important[href] {
  background-color: #953b39;
}

.label-warning, .badge-warning {
  background-color: #f89406;
}

.label-warning[href], .badge-warning[href] {
  background-color: #c67605;
}

.label-success, .badge-success {
  background-color: #468847;
}

.label-success[href], .badge-success[href] {
  background-color: #356635;
}

.label-info, .badge-info {
  background-color: #3a87ad;
}

.label-info[href], .badge-info[href] {
  background-color: #2d6987;
}

.label-inverse, .badge-inverse {
  background-color: #333;
}

.label-inverse[href], .badge-inverse[href] {
  background-color: #1a1a1a;
}

.btn .label, .btn .badge {
  position: relative;
  top: -1px;
}

.btn-mini .label, .btn-mini .badge {
  top: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #0000;
  max-width: 100%;
}

.table {
  width: 100%;
  margin-bottom: 18px;
}

.table th, .table td {
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 18px;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th, .table caption + thead tr:first-child td, .table colgroup + thead tr:first-child th, .table colgroup + thead tr:first-child td, .table thead:first-child tr:first-child th, .table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #ddd;
}

.table-condensed th, .table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border-collapse: separate;
  border: 1px solid #ddd;
  border-left: 0;
  border-radius: 4px;
}

.table-bordered th, .table-bordered td {
  border-left: 1px solid #ddd;
}

.table-bordered caption + thead tr:first-child th, .table-bordered caption + tbody tr:first-child th, .table-bordered caption + tbody tr:first-child td, .table-bordered colgroup + thead tr:first-child th, .table-bordered colgroup + tbody tr:first-child th, .table-bordered colgroup + tbody tr:first-child td, .table-bordered thead:first-child tr:first-child th, .table-bordered tbody:first-child tr:first-child th, .table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child th:first-child, .table-bordered tbody:first-child tr:first-child td:first-child {
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

.table-bordered thead:first-child tr:first-child th:last-child, .table-bordered tbody:first-child tr:first-child td:last-child {
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}

.table-bordered thead:last-child tr:last-child th:first-child, .table-bordered tbody:last-child tr:last-child td:first-child, .table-bordered tfoot:last-child tr:last-child td:first-child {
  -moz-border-radius-bottomleft: 4px;
  border-radius: 0 0 0 4px;
}

.table-bordered thead:last-child tr:last-child th:last-child, .table-bordered tbody:last-child tr:last-child td:last-child, .table-bordered tfoot:last-child tr:last-child td:last-child {
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}

.table-bordered caption + thead tr:first-child th:first-child, .table-bordered caption + tbody tr:first-child td:first-child, .table-bordered colgroup + thead tr:first-child th:first-child, .table-bordered colgroup + tbody tr:first-child td:first-child {
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child, .table-bordered caption + tbody tr:first-child td:last-child, .table-bordered colgroup + thead tr:first-child th:last-child, .table-bordered colgroup + tbody tr:first-child td:last-child {
  -moz-border-right-topleft: 4px;
  border-top-right-radius: 4px;
}

.table-striped tbody tr:nth-child(odd) td, .table-striped tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #f5f5f5;
}

table [class*="span"], .row-fluid table [class*="span"] {
  float: none;
  margin-left: 0;
  display: table-cell;
}

table .span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}

table .span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}

table .span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}

table .span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}

table .span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}

table .span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}

table .span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}

table .span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}

table .span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}

table .span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}

table .span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}

table .span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}

table .span13 {
  float: none;
  width: 1004px;
  margin-left: 0;
}

table .span14 {
  float: none;
  width: 1084px;
  margin-left: 0;
}

table .span15 {
  float: none;
  width: 1164px;
  margin-left: 0;
}

table .span16 {
  float: none;
  width: 1244px;
  margin-left: 0;
}

table .span17 {
  float: none;
  width: 1324px;
  margin-left: 0;
}

table .span18 {
  float: none;
  width: 1404px;
  margin-left: 0;
}

table .span19 {
  float: none;
  width: 1484px;
  margin-left: 0;
}

table .span20 {
  float: none;
  width: 1564px;
  margin-left: 0;
}

table .span21 {
  float: none;
  width: 1644px;
  margin-left: 0;
}

table .span22 {
  float: none;
  width: 1724px;
  margin-left: 0;
}

table .span23 {
  float: none;
  width: 1804px;
  margin-left: 0;
}

table .span24 {
  float: none;
  width: 1884px;
  margin-left: 0;
}

.table tbody tr.success td {
  background-color: #dff0d8;
}

.table tbody tr.error td {
  background-color: #f2dede;
}

.table tbody tr.info td {
  background-color: #d9edf7;
}

form {
  margin: 0 0 18px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  margin-bottom: 18px;
  padding: 0;
  font-size: 21px;
  line-height: 36px;
  display: block;
}

legend small {
  color: #999;
  font-size: 13.5px;
}

label, input, button, select, textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

input, button, select, textarea {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

label {
  margin-bottom: 5px;
  display: block;
}

select, textarea, .uneditable-input, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  color: #555;
  border-radius: 3px;
  height: 18px;
  margin-bottom: 9px;
  padding: 4px 6px;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
}

input, textarea {
  width: 210px;
}

textarea {
  height: auto;
}

textarea, .uneditable-input, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  -o-transition: border linear .2s, box-shadow linear .2s;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: border .2s linear, box-shadow .2s linear;
  box-shadow: inset 0 1px 1px #00000013;
}

textarea:focus, .uneditable-input:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
  outline: 0;
  outline: thin dotted \9 ;
  border-color: #52a8eccc;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #52a8ec99;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  cursor: pointer;
  line-height: normal;
}

input[type="file"], input[type="image"], input[type="submit"], input[type="reset"], input[type="button"], input[type="radio"], input[type="checkbox"] {
  width: auto;
}

select, input[type="file"] {
  height: 30px;
  line-height: 30px;
}

select {
  background-color: #fff;
  border: 1px solid #bbb;
  width: 220px;
}

select[multiple], select[size] {
  height: auto;
}

select:focus, input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.uneditable-input, .uneditable-textarea {
  color: #999;
  cursor: not-allowed;
  background-color: #fcfcfc;
  border-color: #ccc;
  box-shadow: inset 0 1px 2px #00000006;
}

.uneditable-input {
  white-space: nowrap;
  overflow: hidden;
}

.uneditable-textarea {
  width: auto;
  height: auto;
}

input:-moz-placeholder {
  color: #999;
}

textarea:-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999;
}

textarea:-ms-input-placeholder {
  color: #999;
}

input::-webkit-input-placeholder {
  color: #999;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

.radio, .checkbox {
  min-height: 18px;
  padding-left: 18px;
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

.controls > .radio:first-child, .controls > .checkbox:first-child {
  padding-top: 5px;
}

.radio.inline, .checkbox.inline {
  vertical-align: middle;
  margin-bottom: 0;
  padding-top: 5px;
  display: inline-block;
}

.radio.inline + .radio.inline, .checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}

.input-mini {
  width: 60px;
}

.input-small {
  width: 90px;
}

.input-medium {
  width: 150px;
}

.input-large {
  width: 210px;
}

.input-xlarge {
  width: 270px;
}

.input-xxlarge {
  width: 530px;
}

input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input[class*="span"], .row-fluid input[class*="span"], .row-fluid select[class*="span"], .row-fluid textarea[class*="span"], .row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}

.input-append input[class*="span"], .input-append .uneditable-input[class*="span"], .input-prepend input[class*="span"], .input-prepend .uneditable-input[class*="span"], .row-fluid input[class*="span"], .row-fluid select[class*="span"], .row-fluid textarea[class*="span"], .row-fluid .uneditable-input[class*="span"], .row-fluid .input-prepend [class*="span"], .row-fluid .input-append [class*="span"] {
  display: inline-block;
}

input, textarea, .uneditable-input {
  margin-left: 0;
}

.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}

input.span12, textarea.span12, .uneditable-input.span12 {
  width: 926px;
}

input.span11, textarea.span11, .uneditable-input.span11 {
  width: 846px;
}

input.span10, textarea.span10, .uneditable-input.span10 {
  width: 766px;
}

input.span9, textarea.span9, .uneditable-input.span9 {
  width: 686px;
}

input.span8, textarea.span8, .uneditable-input.span8 {
  width: 606px;
}

input.span7, textarea.span7, .uneditable-input.span7 {
  width: 526px;
}

input.span6, textarea.span6, .uneditable-input.span6 {
  width: 446px;
}

input.span5, textarea.span5, .uneditable-input.span5 {
  width: 366px;
}

input.span4, textarea.span4, .uneditable-input.span4 {
  width: 286px;
}

input.span3, textarea.span3, .uneditable-input.span3 {
  width: 206px;
}

input.span2, textarea.span2, .uneditable-input.span2 {
  width: 126px;
}

input.span1, textarea.span1, .uneditable-input.span1 {
  width: 46px;
}

.controls-row:before, .controls-row:after {
  content: "";
  line-height: 0;
  display: table;
}

.controls-row:after {
  clear: both;
}

.controls-row [class*="span"] {
  float: left;
}

input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
  cursor: not-allowed;
  background-color: #eee;
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
  background-color: #0000;
}

.control-group.warning > label, .control-group.warning .help-block, .control-group.warning .help-inline {
  color: #c09853;
}

.control-group.warning .checkbox, .control-group.warning .radio, .control-group.warning input, .control-group.warning select, .control-group.warning textarea {
  color: #c09853;
  border-color: #c09853;
  box-shadow: inset 0 1px 1px #00000013;
}

.control-group.warning .checkbox:focus, .control-group.warning .radio:focus, .control-group.warning input:focus, .control-group.warning select:focus, .control-group.warning textarea:focus {
  border-color: #a47e3c;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #dbc59e;
}

.control-group.warning .input-prepend .add-on, .control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}

.control-group.error > label, .control-group.error .help-block, .control-group.error .help-inline {
  color: #b94a48;
}

.control-group.error .checkbox, .control-group.error .radio, .control-group.error input, .control-group.error select, .control-group.error textarea {
  color: #b94a48;
  border-color: #b94a48;
  box-shadow: inset 0 1px 1px #00000013;
}

.control-group.error .checkbox:focus, .control-group.error .radio:focus, .control-group.error input:focus, .control-group.error select:focus, .control-group.error textarea:focus {
  border-color: #953b39;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #d59392;
}

.control-group.error .input-prepend .add-on, .control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}

.control-group.success > label, .control-group.success .help-block, .control-group.success .help-inline {
  color: #468847;
}

.control-group.success .checkbox, .control-group.success .radio, .control-group.success input, .control-group.success select, .control-group.success textarea {
  color: #468847;
  border-color: #468847;
  box-shadow: inset 0 1px 1px #00000013;
}

.control-group.success .checkbox:focus, .control-group.success .radio:focus, .control-group.success input:focus, .control-group.success select:focus, .control-group.success textarea:focus {
  border-color: #356635;
  box-shadow: inset 0 1px 1px #00000013, 0 0 6px #7aba7b;
}

.control-group.success .input-prepend .add-on, .control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}

input:focus:required:invalid, textarea:focus:required:invalid, select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}

input:focus:required:invalid:focus, textarea:focus:required:invalid:focus, select:focus:required:invalid:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.form-actions {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 17px 20px 18px;
}

.form-actions:before, .form-actions:after {
  content: "";
  line-height: 0;
  display: table;
}

.form-actions:after {
  clear: both;
}

.help-block, .help-inline {
  color: #595959;
}

.help-block {
  margin-bottom: 9px;
  display: block;
}

.help-inline {
  vertical-align: middle;
  padding-left: 5px;
  display: inline-block;
}

.input-append, .input-prepend {
  white-space: nowrap;
  margin-bottom: 5px;
  font-size: 0;
}

.input-append input, .input-prepend input, .input-append select, .input-prepend select, .input-append .uneditable-input, .input-prepend .uneditable-input {
  vertical-align: top;
  border-radius: 0 3px 3px 0;
  margin-bottom: 0;
  font-size: 14px;
  position: relative;
}

.input-append input:focus, .input-prepend input:focus, .input-append select:focus, .input-prepend select:focus, .input-append .uneditable-input:focus, .input-prepend .uneditable-input:focus {
  z-index: 2;
}

.input-append .add-on, .input-prepend .add-on {
  text-align: center;
  text-shadow: 0 1px #fff;
  background-color: #eee;
  border: 1px solid #ccc;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  display: inline-block;
}

.input-append .add-on, .input-prepend .add-on, .input-append .btn, .input-prepend .btn {
  vertical-align: top;
  border-radius: 0;
  margin-left: -1px;
}

.input-append .active, .input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}

.input-prepend .add-on, .input-prepend .btn {
  margin-right: -1px;
}

.input-prepend .add-on:first-child, .input-prepend .btn:first-child, .input-append input, .input-append select, .input-append .uneditable-input {
  border-radius: 3px 0 0 3px;
}

.input-append .add-on:last-child, .input-append .btn:last-child {
  border-radius: 0 3px 3px 0;
}

.input-prepend.input-append input, .input-prepend.input-append select, .input-prepend.input-append .uneditable-input {
  border-radius: 0;
}

.input-prepend.input-append .add-on:first-child, .input-prepend.input-append .btn:first-child {
  border-radius: 3px 0 0 3px;
  margin-right: -1px;
}

.input-prepend.input-append .add-on:last-child, .input-prepend.input-append .btn:last-child {
  border-radius: 0 3px 3px 0;
  margin-left: -1px;
}

input.search-query {
  padding-right: 14px;
  padding-right: 4px \9 ;
  padding-left: 14px;
  padding-left: 4px \9 ;
  border-radius: 15px;
  margin-bottom: 0;
}

.form-search .input-append .search-query, .form-search .input-prepend .search-query {
  border-radius: 0;
}

.form-search .input-append .search-query {
  border-radius: 14px 0 0 14px;
}

.form-search .input-append .btn, .form-search .input-prepend .search-query {
  border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .btn {
  border-radius: 14px 0 0 14px;
}

.form-search input, .form-inline input, .form-horizontal input, .form-search textarea, .form-inline textarea, .form-horizontal textarea, .form-search select, .form-inline select, .form-horizontal select, .form-search .help-inline, .form-inline .help-inline, .form-horizontal .help-inline, .form-search .uneditable-input, .form-inline .uneditable-input, .form-horizontal .uneditable-input, .form-search .input-prepend, .form-inline .input-prepend, .form-horizontal .input-prepend, .form-search .input-append, .form-inline .input-append, .form-horizontal .input-append {
  vertical-align: middle;
  margin-bottom: 0;
  display: inline-block;
}

.form-search .hide, .form-inline .hide, .form-horizontal .hide {
  display: none;
}

.form-search label, .form-inline label, .form-search .btn-group, .form-inline .btn-group {
  display: inline-block;
}

.form-search .input-append, .form-inline .input-append, .form-search .input-prepend, .form-inline .input-prepend {
  margin-bottom: 0;
}

.form-search .radio, .form-search .checkbox, .form-inline .radio, .form-inline .checkbox {
  vertical-align: middle;
  margin-bottom: 0;
  padding-left: 0;
}

.form-search .radio input[type="radio"], .form-search .checkbox input[type="checkbox"], .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-left: 0;
  margin-right: 3px;
}

.control-group {
  margin-bottom: 9px;
}

legend + .control-group {
  -webkit-margin-top-collapse: separate;
  margin-top: 18px;
}

.form-horizontal .control-group {
  margin-bottom: 18px;
}

.form-horizontal .control-group:before, .form-horizontal .control-group:after {
  content: "";
  line-height: 0;
  display: table;
}

.form-horizontal .control-group:after {
  clear: both;
}

.form-horizontal .control-label {
  float: left;
  text-align: right;
  width: 140px;
  padding-top: 5px;
}

.form-horizontal .controls {
  margin-left: 160px;
}

.form-horizontal .help-block {
  margin-top: 9px;
  margin-bottom: 0;
}

.form-horizontal .form-actions {
  padding-left: 160px;
}

.btn {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333;
  text-shadow: 0 1px 1px #ffffffbf;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-color: #f5f5f5;
  background-image: linear-gradient(#fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #bbb;
  border-bottom-color: #a2a2a2;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 4px 14px;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  box-shadow: inset 0 1px #fff3, 0 1px 2px #0000000d;
}

.btn:hover, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
  color: #333;
  background-color: #e6e6e6;
}

.btn:active, .btn.active {
  background-color: #ccc \9 ;
}

.btn:hover {
  color: #333;
  -o-transition: background-position .1s linear;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  text-decoration: none;
  transition: background-position .1s linear;
}

.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn.active, .btn:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9 ;
  background-image: none;
  outline: 0;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.btn.disabled, .btn[disabled] {
  cursor: default;
  opacity: .65;
  filter: alpha(opacity= 65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #e6e6e6;
  background-image: none;
}

.btn-large {
  border-radius: 5px;
  padding: 9px 14px;
  font-size: 16px;
  line-height: normal;
}

.btn-large [class^="icon-"] {
  margin-top: 2px;
}

.btn-small {
  padding: 3px 9px;
  font-size: 12px;
  line-height: 16px;
}

.btn-small [class^="icon-"] {
  margin-top: 0;
}

.btn-mini {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 14px;
}

.btn-block {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

.btn-primary.active, .btn-warning.active, .btn-danger.active, .btn-success.active, .btn-info.active, .btn-inverse.active {
  color: #ffffffbf;
}

.btn {
  border-color: #00000026 #00000026 #00000040;
}

.btn-primary {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #08c, #04c);
  background-color: #006dcc;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
  color: #fff;
  background-color: #04c;
}

.btn-primary:active, .btn-primary.active {
  background-color: #039 \9 ;
}

.btn-warning {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-color: #faa732;
  background-image: linear-gradient(#fbb450, #f89406);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
  color: #fff;
  background-color: #f89406;
}

.btn-warning:active, .btn-warning.active {
  background-color: #c67605 \9 ;
}

.btn-danger {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-color: #da4f49;
  background-image: linear-gradient(#ee5f5b, #bd362f);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
  color: #fff;
  background-color: #bd362f;
}

.btn-danger:active, .btn-danger.active {
  background-color: #942a25 \9 ;
}

.btn-success {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-color: #5bb75b;
  background-image: linear-gradient(#62c462, #51a351);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
}

.btn-success:active, .btn-success.active {
  background-color: #408140 \9 ;
}

.btn-info {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-color: #49afcd;
  background-image: linear-gradient(#5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
  color: #fff;
  background-color: #2f96b4;
}

.btn-info:active, .btn-info.active {
  background-color: #24748c \9 ;
}

.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #444, #222);
  background-color: #363636;
  background-image: linear-gradient(#444, #222);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
  color: #fff;
  background-color: #222;
}

.btn-inverse:active, .btn-inverse.active {
  background-color: #080808 \9 ;
}

button.btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"].btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn-link, .btn-link:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
  background-image: none;
}

.btn-link {
  cursor: pointer;
  color: #08c;
  border-color: #0000;
  border-radius: 0;
}

.btn-link:hover {
  color: #005580;
  background-color: #0000;
  text-decoration: underline;
}

[class^="icon-"], [class*=" icon-"] {
  vertical-align: text-top;
  background-image: url("glyphicons-halflings.8286579a.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  line-height: 14px;
  display: inline-block;
}

.icon-white, .nav > .active > a > [class^="icon-"], .nav > .active > a > [class*=" icon-"], .dropdown-menu > li > a:hover > [class^="icon-"], .dropdown-menu > li > a:hover > [class*=" icon-"], .dropdown-menu > .active > a > [class^="icon-"], .dropdown-menu > .active > a > [class*=" icon-"] {
  background-image: url("glyphicons-halflings-white.5f97806b.png");
}

.icon-glass {
  background-position: 0 0;
}

.icon-music {
  background-position: -24px 0;
}

.icon-search {
  background-position: -48px 0;
}

.icon-envelope {
  background-position: -72px 0;
}

.icon-heart {
  background-position: -96px 0;
}

.icon-star {
  background-position: -120px 0;
}

.icon-star-empty {
  background-position: -144px 0;
}

.icon-user {
  background-position: -168px 0;
}

.icon-film {
  background-position: -192px 0;
}

.icon-th-large {
  background-position: -216px 0;
}

.icon-th {
  background-position: -240px 0;
}

.icon-th-list {
  background-position: -264px 0;
}

.icon-ok {
  background-position: -288px 0;
}

.icon-remove {
  background-position: -312px 0;
}

.icon-zoom-in {
  background-position: -336px 0;
}

.icon-zoom-out {
  background-position: -360px 0;
}

.icon-off {
  background-position: -384px 0;
}

.icon-signal {
  background-position: -408px 0;
}

.icon-cog {
  background-position: -432px 0;
}

.icon-trash {
  background-position: -456px 0;
}

.icon-home {
  background-position: 0 -24px;
}

.icon-file {
  background-position: -24px -24px;
}

.icon-time {
  background-position: -48px -24px;
}

.icon-road {
  background-position: -72px -24px;
}

.icon-download-alt {
  background-position: -96px -24px;
}

.icon-download {
  background-position: -120px -24px;
}

.icon-upload {
  background-position: -144px -24px;
}

.icon-inbox {
  background-position: -168px -24px;
}

.icon-play-circle {
  background-position: -192px -24px;
}

.icon-repeat {
  background-position: -216px -24px;
}

.icon-refresh {
  background-position: -240px -24px;
}

.icon-list-alt {
  background-position: -264px -24px;
}

.icon-lock {
  background-position: -287px -24px;
}

.icon-flag {
  background-position: -312px -24px;
}

.icon-headphones {
  background-position: -336px -24px;
}

.icon-volume-off {
  background-position: -360px -24px;
}

.icon-volume-down {
  background-position: -384px -24px;
}

.icon-volume-up {
  background-position: -408px -24px;
}

.icon-qrcode {
  background-position: -432px -24px;
}

.icon-barcode {
  background-position: -456px -24px;
}

.icon-tag {
  background-position: 0 -48px;
}

.icon-tags {
  background-position: -25px -48px;
}

.icon-book {
  background-position: -48px -48px;
}

.icon-bookmark {
  background-position: -72px -48px;
}

.icon-print {
  background-position: -96px -48px;
}

.icon-camera {
  background-position: -120px -48px;
}

.icon-font {
  background-position: -144px -48px;
}

.icon-bold {
  background-position: -167px -48px;
}

.icon-italic {
  background-position: -192px -48px;
}

.icon-text-height {
  background-position: -216px -48px;
}

.icon-text-width {
  background-position: -240px -48px;
}

.icon-align-left {
  background-position: -264px -48px;
}

.icon-align-center {
  background-position: -288px -48px;
}

.icon-align-right {
  background-position: -312px -48px;
}

.icon-align-justify {
  background-position: -336px -48px;
}

.icon-list {
  background-position: -360px -48px;
}

.icon-indent-left {
  background-position: -384px -48px;
}

.icon-indent-right {
  background-position: -408px -48px;
}

.icon-facetime-video {
  background-position: -432px -48px;
}

.icon-picture {
  background-position: -456px -48px;
}

.icon-pencil {
  background-position: 0 -72px;
}

.icon-map-marker {
  background-position: -24px -72px;
}

.icon-adjust {
  background-position: -48px -72px;
}

.icon-tint {
  background-position: -72px -72px;
}

.icon-edit {
  background-position: -96px -72px;
}

.icon-share {
  background-position: -120px -72px;
}

.icon-check {
  background-position: -144px -72px;
}

.icon-move {
  background-position: -168px -72px;
}

.icon-step-backward {
  background-position: -192px -72px;
}

.icon-fast-backward {
  background-position: -216px -72px;
}

.icon-backward {
  background-position: -240px -72px;
}

.icon-play {
  background-position: -264px -72px;
}

.icon-pause {
  background-position: -288px -72px;
}

.icon-stop {
  background-position: -312px -72px;
}

.icon-forward {
  background-position: -336px -72px;
}

.icon-fast-forward {
  background-position: -360px -72px;
}

.icon-step-forward {
  background-position: -384px -72px;
}

.icon-eject {
  background-position: -408px -72px;
}

.icon-chevron-left {
  background-position: -432px -72px;
}

.icon-chevron-right {
  background-position: -456px -72px;
}

.icon-plus-sign {
  background-position: 0 -96px;
}

.icon-minus-sign {
  background-position: -24px -96px;
}

.icon-remove-sign {
  background-position: -48px -96px;
}

.icon-ok-sign {
  background-position: -72px -96px;
}

.icon-question-sign {
  background-position: -96px -96px;
}

.icon-info-sign {
  background-position: -120px -96px;
}

.icon-screenshot {
  background-position: -144px -96px;
}

.icon-remove-circle {
  background-position: -168px -96px;
}

.icon-ok-circle {
  background-position: -192px -96px;
}

.icon-ban-circle {
  background-position: -216px -96px;
}

.icon-arrow-left {
  background-position: -240px -96px;
}

.icon-arrow-right {
  background-position: -264px -96px;
}

.icon-arrow-up {
  background-position: -289px -96px;
}

.icon-arrow-down {
  background-position: -312px -96px;
}

.icon-share-alt {
  background-position: -336px -96px;
}

.icon-resize-full {
  background-position: -360px -96px;
}

.icon-resize-small {
  background-position: -384px -96px;
}

.icon-plus {
  background-position: -408px -96px;
}

.icon-minus {
  background-position: -433px -96px;
}

.icon-asterisk {
  background-position: -456px -96px;
}

.icon-exclamation-sign {
  background-position: 0 -120px;
}

.icon-gift {
  background-position: -24px -120px;
}

.icon-leaf {
  background-position: -48px -120px;
}

.icon-fire {
  background-position: -72px -120px;
}

.icon-eye-open {
  background-position: -96px -120px;
}

.icon-eye-close {
  background-position: -120px -120px;
}

.icon-warning-sign {
  background-position: -144px -120px;
}

.icon-plane {
  background-position: -168px -120px;
}

.icon-calendar {
  background-position: -192px -120px;
}

.icon-random {
  background-position: -216px -120px;
  width: 16px;
}

.icon-comment {
  background-position: -240px -120px;
}

.icon-magnet {
  background-position: -264px -120px;
}

.icon-chevron-up {
  background-position: -288px -120px;
}

.icon-chevron-down {
  background-position: -313px -119px;
}

.icon-retweet {
  background-position: -336px -120px;
}

.icon-shopping-cart {
  background-position: -360px -120px;
}

.icon-folder-close {
  background-position: -384px -120px;
}

.icon-folder-open {
  background-position: -408px -120px;
  width: 16px;
}

.icon-resize-vertical {
  background-position: -432px -119px;
}

.icon-resize-horizontal {
  background-position: -456px -118px;
}

.icon-hdd {
  background-position: 0 -144px;
}

.icon-bullhorn {
  background-position: -24px -144px;
}

.icon-bell {
  background-position: -48px -144px;
}

.icon-certificate {
  background-position: -72px -144px;
}

.icon-thumbs-up {
  background-position: -96px -144px;
}

.icon-thumbs-down {
  background-position: -120px -144px;
}

.icon-hand-right {
  background-position: -144px -144px;
}

.icon-hand-left {
  background-position: -168px -144px;
}

.icon-hand-up {
  background-position: -192px -144px;
}

.icon-hand-down {
  background-position: -216px -144px;
}

.icon-circle-arrow-right {
  background-position: -240px -144px;
}

.icon-circle-arrow-left {
  background-position: -264px -144px;
}

.icon-circle-arrow-up {
  background-position: -288px -144px;
}

.icon-circle-arrow-down {
  background-position: -312px -144px;
}

.icon-globe {
  background-position: -336px -144px;
}

.icon-wrench {
  background-position: -360px -144px;
}

.icon-tasks {
  background-position: -384px -144px;
}

.icon-filter {
  background-position: -408px -144px;
}

.icon-briefcase {
  background-position: -432px -144px;
}

.icon-fullscreen {
  background-position: -456px -144px;
}

.btn-group {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.btn-group + .btn-group {
  margin-left: 5px;
}

.btn-toolbar {
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 0;
}

.btn-toolbar .btn-group {
  display: inline-block;
}

.btn-toolbar .btn + .btn, .btn-toolbar .btn-group + .btn, .btn-toolbar .btn + .btn-group {
  margin-left: 5px;
}

.btn-group > .btn {
  border-radius: 0;
  position: relative;
}

.btn-group > .btn + .btn {
  margin-left: -1px;
}

.btn-group > .btn, .btn-group > .dropdown-menu {
  font-size: 14px;
}

.btn-group > .btn-mini {
  font-size: 11px;
}

.btn-group > .btn-small {
  font-size: 12px;
}

.btn-group > .btn-large {
  font-size: 16px;
}

.btn-group > .btn:first-child {
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0;
}

.btn-group > .btn:last-child, .btn-group > .dropdown-toggle {
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-group > .btn.large:first-child {
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-left: 0;
}

.btn-group > .btn.large:last-child, .btn-group > .large.dropdown-toggle {
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 2;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: inset 1px 0 #ffffff20, inset 0 1px #fff3, 0 1px 2px #0000000d;
}

.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  background-image: none;
  box-shadow: inset 0 2px 4px #00000026, 0 1px 2px #0000000d;
}

.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #04c;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}

.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222;
}

.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}

.btn-mini .caret, .btn-small .caret, .btn-large .caret {
  margin-top: 6px;
}

.btn-large .caret {
  border-top-width: 5px;
  border-left-width: 5px;
  border-right-width: 5px;
}

.dropup .btn-large .caret {
  border-top: 0;
  border-bottom: 5px solid #000;
}

.btn-primary .caret, .btn-warning .caret, .btn-danger .caret, .btn-info .caret, .btn-success .caret, .btn-inverse .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.btn-group-vertical {
  display: inline-block;
}

.btn-group-vertical .btn {
  float: none;
  border-radius: 0;
  width: 100%;
  display: block;
}

.btn-group-vertical .btn + .btn {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical .btn:first-child {
  border-radius: 4px 4px 0 0;
}

.btn-group-vertical .btn:last-child {
  border-radius: 0 0 4px 4px;
}

.btn-group-vertical .btn-large:first-child {
  border-radius: 6px 6px 0 0;
}

.btn-group-vertical .btn-large:last-child {
  border-radius: 0 0 6px 6px;
}

.nav {
  margin-bottom: 18px;
  margin-left: 0;
  list-style: none;
}

.nav > li > a {
  display: block;
}

.nav > li > a:hover {
  background-color: #eee;
  text-decoration: none;
}

.nav > .pull-right {
  float: right;
}

.nav-header {
  color: #999;
  text-shadow: 0 1px #ffffff80;
  text-transform: uppercase;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  display: block;
}

.nav li + .nav-header {
  margin-top: 9px;
}

.nav-list {
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.nav-list > li > a, .nav-list .nav-header {
  text-shadow: 0 1px #ffffff80;
  margin-left: -15px;
  margin-right: -15px;
}

.nav-list > li > a {
  padding: 3px 15px;
}

.nav-list > .active > a, .nav-list > .active > a:hover {
  color: #fff;
  text-shadow: 0 -1px #0003;
  background-color: #08c;
}

.nav-list [class^="icon-"] {
  margin-right: 2px;
}

.nav-list .divider {
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
  height: 1px;
  margin: 8px 1px;
  overflow: hidden;
}

.nav-tabs:before, .nav-pills:before, .nav-tabs:after, .nav-pills:after {
  content: "";
  line-height: 0;
  display: table;
}

.nav-tabs:after, .nav-pills:after {
  clear: both;
}

.nav-tabs > li, .nav-pills > li {
  float: left;
}

.nav-tabs > li > a, .nav-pills > li > a {
  margin-right: 2px;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 14px;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #0000;
}

.nav-pills > li > a {
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-pills > .active > a, .nav-pills > .active > a:hover {
  color: #fff;
  background-color: #08c;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li > a {
  margin-right: 0;
}

.nav-tabs.nav-stacked {
  border-bottom: 0;
}

.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  border-radius: 0;
}

.nav-tabs.nav-stacked > li:first-child > a {
  -moz-border-radius-topright: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs.nav-stacked > li:last-child > a {
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.nav-tabs.nav-stacked > li > a:hover {
  z-index: 2;
  border-color: #ddd;
}

.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}

.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}

.nav-tabs .dropdown-menu {
  border-radius: 0 0 6px 6px;
}

.nav-pills .dropdown-menu {
  border-radius: 6px;
}

.nav .dropdown-toggle .caret {
  border-top-color: #08c;
  border-bottom-color: #08c;
  margin-top: 6px;
}

.nav .dropdown-toggle:hover .caret {
  border-top-color: #005580;
  border-bottom-color: #005580;
}

.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}

.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}

.nav > .dropdown.active > a:hover {
  cursor: pointer;
}

.nav-tabs .open .dropdown-toggle, .nav-pills .open .dropdown-toggle, .nav > li.dropdown.open.active > a:hover {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.nav li.dropdown.open .caret, .nav li.dropdown.open.active .caret, .nav li.dropdown.open a:hover .caret {
  opacity: 1;
  filter: alpha(opacity= 100);
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.tabs-stacked .open > a:hover {
  border-color: #999;
}

.tabbable:before, .tabbable:after {
  content: "";
  line-height: 0;
  display: table;
}

.tabbable:after {
  clear: both;
}

.tab-content {
  overflow: auto;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
}

.tab-content > .active, .pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover {
  border-top-color: #ddd;
  border-bottom-color: #0000;
}

.tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover {
  border-color: #0000 #ddd #ddd;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-bottom: 3px;
  margin-right: 0;
}

.tabs-left > .nav-tabs {
  float: left;
  border-right: 1px solid #ddd;
  margin-right: 19px;
}

.tabs-left > .nav-tabs > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: -1px;
}

.tabs-left > .nav-tabs > li > a:hover {
  border-color: #eee #ddd #eee #eee;
}

.tabs-left > .nav-tabs .active > a, .tabs-left > .nav-tabs .active > a:hover {
  border-color: #ddd #0000 #ddd #ddd;
}

.tabs-right > .nav-tabs {
  float: right;
  border-left: 1px solid #ddd;
  margin-left: 19px;
}

.tabs-right > .nav-tabs > li > a {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.tabs-right > .nav-tabs > li > a:hover {
  border-color: #eee #eee #eee #ddd;
}

.tabs-right > .nav-tabs .active > a, .tabs-right > .nav-tabs .active > a:hover {
  border-color: #ddd #ddd #ddd #0000;
}

.nav > .disabled > a {
  color: #999;
}

.nav > .disabled > a:hover {
  cursor: default;
  background-color: #0000;
  text-decoration: none;
}

.navbar {
  color: #555;
  margin-bottom: 18px;
  overflow: visible;
}

.navbar-inner {
  min-height: 40px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffffff", endColorstr= "#fff2f2f2", GradientType= 0);
  background-image: -moz-linear-gradient(top, #fff, #f2f2f2);
  background-color: #fafafa;
  background-image: linear-gradient(#fff, #f2f2f2);
  background-repeat: repeat-x;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 1px 4px #0001;
}

.navbar .container {
  width: auto;
}

.nav-collapse.collapse {
  height: auto;
}

.navbar .brand {
  float: left;
  color: #555;
  text-shadow: 0 1px #fff;
  margin-left: -20px;
  padding: 11px 20px;
  font-size: 20px;
  font-weight: 200;
  display: block;
}

.navbar .brand:hover {
  text-decoration: none;
}

.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
}

.navbar-link {
  color: #555;
}

.navbar-link:hover {
  color: #333;
}

.navbar .divider-vertical {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #fff;
  height: 40px;
  margin: 0 9px;
}

.navbar .btn, .navbar .btn-group {
  margin-top: 6px;
}

.navbar .btn-group .btn {
  margin: 0;
}

.navbar-form {
  margin-bottom: 0;
}

.navbar-form:before, .navbar-form:after {
  content: "";
  line-height: 0;
  display: table;
}

.navbar-form:after {
  clear: both;
}

.navbar-form input, .navbar-form select, .navbar-form .radio, .navbar-form .checkbox {
  margin-top: 5px;
}

.navbar-form input, .navbar-form select, .navbar-form .btn {
  margin-bottom: 0;
  display: inline-block;
}

.navbar-form input[type="image"], .navbar-form input[type="checkbox"], .navbar-form input[type="radio"] {
  margin-top: 3px;
}

.navbar-form .input-append, .navbar-form .input-prepend {
  white-space: nowrap;
  margin-top: 6px;
}

.navbar-form .input-append input, .navbar-form .input-prepend input {
  margin-top: 0;
}

.navbar-search {
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
  position: relative;
}

.navbar-search .search-query {
  border-radius: 15px;
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
}

.navbar-static-top {
  width: 100%;
  margin-bottom: 0;
  position: static;
}

.navbar-static-top .navbar-inner {
  border-radius: 0;
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 1030;
  margin-bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar-fixed-top .navbar-inner, .navbar-fixed-bottom .navbar-inner, .navbar-static-top .navbar-inner {
  border: 0;
}

.navbar-fixed-top .navbar-inner, .navbar-fixed-bottom .navbar-inner {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
  width: 940px;
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-top .navbar-inner, .navbar-static-top .navbar-inner {
  box-shadow: inset 0 -1px #0000001a, 0 1px 10px #0000001a;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-fixed-bottom .navbar-inner {
  box-shadow: inset 0 1px #0000001a, 0 -1px 10px #0000001a;
}

.navbar .nav {
  float: left;
  margin: 0 10px 0 0;
  display: block;
  position: relative;
  left: 0;
}

.navbar .nav.pull-right {
  float: right;
}

.navbar .nav > li {
  float: left;
}

.navbar .nav > li > a {
  float: none;
  color: #555;
  text-shadow: 0 1px #fff;
  padding: 11px 15px;
  text-decoration: none;
}

.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}

.navbar .nav > li > a:focus, .navbar .nav > li > a:hover {
  color: #333;
  background-color: #0000;
  text-decoration: none;
}

.navbar .nav > .active > a, .navbar .nav > .active > a:hover, .navbar .nav > .active > a:focus {
  color: #555;
  background-color: #e5e5e5;
  text-decoration: none;
  box-shadow: inset 0 3px 8px #00000020;
}

.navbar .btn-navbar {
  float: right;
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-color: #ededed;
  background-image: linear-gradient(#f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
  margin-left: 5px;
  margin-right: 5px;
  padding: 7px 10px;
  display: none;
  box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff13;
}

.navbar .btn-navbar:hover, .navbar .btn-navbar:active, .navbar .btn-navbar.active, .navbar .btn-navbar.disabled, .navbar .btn-navbar[disabled] {
  color: #fff;
  background-color: #e5e5e5;
}

.navbar .btn-navbar:active, .navbar .btn-navbar.active {
  background-color: #ccc \9 ;
}

.navbar .btn-navbar .icon-bar {
  background-color: #f5f5f5;
  border-radius: 1px;
  width: 18px;
  height: 2px;
  display: block;
  box-shadow: 0 1px #00000040;
}

.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}

.navbar .nav > li > .dropdown-menu:before {
  content: "";
  border-bottom: 7px solid #0003;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 9px;
}

.navbar .nav > li > .dropdown-menu:after {
  content: "";
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 10px;
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #0003;
  border-bottom: 0;
  top: auto;
  bottom: -7px;
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  top: auto;
  bottom: -6px;
}

.navbar .nav li.dropdown.open > .dropdown-toggle, .navbar .nav li.dropdown.active > .dropdown-toggle, .navbar .nav li.dropdown.open.active > .dropdown-toggle {
  color: #555;
  background-color: #e5e5e5;
}

.navbar .nav li.dropdown > .dropdown-toggle .caret, .navbar .nav li.dropdown.open > .dropdown-toggle .caret, .navbar .nav li.dropdown.active > .dropdown-toggle .caret, .navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}

.navbar .pull-right > li > .dropdown-menu, .navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.navbar .pull-right > li > .dropdown-menu:before, .navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}

.navbar .pull-right > li > .dropdown-menu:after, .navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}

.navbar .pull-right > li > .dropdown-menu .dropdown-menu, .navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  border-radius: 6px 0 6px 6px;
  margin-left: 0;
  margin-right: -1px;
  left: auto;
  right: 100%;
}

.navbar-inverse {
  color: #999;
}

.navbar-inverse .navbar-inner {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff222222", endColorstr= "#ff111111", GradientType= 0);
  background-image: -moz-linear-gradient(top, #222, #111);
  background-color: #1b1b1b;
  background-image: linear-gradient(#222, #111);
  background-repeat: repeat-x;
  border-color: #252525;
}

.navbar-inverse .brand, .navbar-inverse .nav > li > a {
  color: #999;
  text-shadow: 0 -1px #00000040;
}

.navbar-inverse .brand:hover, .navbar-inverse .nav > li > a:hover {
  color: #fff;
}

.navbar-inverse .nav > li > a:focus, .navbar-inverse .nav > li > a:hover {
  color: #fff;
  background-color: #0000;
}

.navbar-inverse .nav .active > a, .navbar-inverse .nav .active > a:hover, .navbar-inverse .nav .active > a:focus {
  color: #fff;
  background-color: #111;
}

.navbar-inverse .navbar-link {
  color: #999;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .divider-vertical {
  border-left-color: #111;
  border-right-color: #222;
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle, .navbar-inverse .nav li.dropdown.active > .dropdown-toggle, .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  color: #fff;
  background-color: #111;
}

.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999;
  border-bottom-color: #999;
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret, .navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret, .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.navbar-inverse .navbar-search .search-query {
  color: #fff;
  -o-transition: none;
  background-color: #515151;
  border-color: #111;
  transition: none;
  box-shadow: inset 0 1px 2px #0000001a, 0 1px #ffffff26;
}

.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #ccc;
}

.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #ccc;
}

.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #ccc;
}

.navbar-inverse .navbar-search .search-query:focus, .navbar-inverse .navbar-search .search-query.focused {
  color: #333;
  text-shadow: 0 1px #fff;
  background-color: #fff;
  border: 0;
  outline: 0;
  padding: 5px 15px;
  box-shadow: 0 0 3px #00000026;
}

.navbar-inverse .btn-navbar {
  color: #fff;
  text-shadow: 0 -1px #00000040;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-color: #0e0e0e;
  background-image: linear-gradient(#151515, #040404);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.navbar-inverse .btn-navbar:hover, .navbar-inverse .btn-navbar:active, .navbar-inverse .btn-navbar.active, .navbar-inverse .btn-navbar.disabled, .navbar-inverse .btn-navbar[disabled] {
  color: #fff;
  background-color: #040404;
}

.navbar-inverse .btn-navbar:active, .navbar-inverse .btn-navbar.active {
  background-color: #000 \9 ;
}

.breadcrumb {
  background-color: #f5f5f5;
  border-radius: 4px;
  margin: 0 0 18px;
  padding: 8px 15px;
  list-style: none;
}

.breadcrumb li {
  text-shadow: 0 1px #fff;
  display: inline-block;
}

.breadcrumb .divider {
  color: #ccc;
  padding: 0 5px;
}

.breadcrumb .active {
  color: #999;
}

.pagination {
  height: 36px;
  margin: 18px 0;
}

.pagination ul {
  border-radius: 3px;
  margin-bottom: 0;
  margin-left: 0;
  display: inline-block;
  box-shadow: 0 1px 2px #0000000d;
}

.pagination li {
  display: inline;
}

.pagination a, .pagination span {
  float: left;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left-width: 0;
  padding: 0 14px;
  line-height: 34px;
  text-decoration: none;
}

.pagination a:hover, .pagination .active a, .pagination .active span {
  background-color: #f5f5f5;
}

.pagination .active a, .pagination .active span {
  color: #999;
  cursor: default;
}

.pagination .disabled span, .pagination .disabled a, .pagination .disabled a:hover {
  color: #999;
  cursor: default;
  background-color: #0000;
}

.pagination li:first-child a, .pagination li:first-child span {
  border-left-width: 1px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a, .pagination li:last-child span {
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  text-align: center;
  margin: 18px 0;
  list-style: none;
}

.pager:before, .pager:after {
  content: "";
  line-height: 0;
  display: table;
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager a {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager a:hover {
  background-color: #f5f5f5;
  text-decoration: none;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a, .pager .disabled a:hover {
  color: #999;
  cursor: default;
  background-color: #fff;
}

.thumbnails {
  margin-left: -20px;
  list-style: none;
}

.thumbnails:before, .thumbnails:after {
  content: "";
  line-height: 0;
  display: table;
}

.thumbnails:after {
  clear: both;
}

.row-fluid .thumbnails {
  margin-left: 0;
}

.thumbnails > li {
  float: left;
  margin-bottom: 18px;
  margin-left: 20px;
}

.thumbnail {
  -o-transition: all .2s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  line-height: 18px;
  transition: all .2s ease-in-out;
  display: block;
  box-shadow: 0 1px 3px #0000000e;
}

a.thumbnail:hover {
  border-color: #08c;
  box-shadow: 0 1px 4px #0069d640;
}

.thumbnail > img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.thumbnail .caption {
  color: #555;
  padding: 9px;
}

.alert {
  text-shadow: 0 1px #ffffff80;
  color: #c09853;
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  margin-bottom: 18px;
  padding: 8px 35px 8px 14px;
}

.alert h4 {
  margin: 0;
}

.alert .close {
  line-height: 18px;
  position: relative;
  top: -2px;
  right: -21px;
}

.alert-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger, .alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.alert-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}

.alert-block > p, .alert-block > ul {
  margin-bottom: 0;
}

.alert-block p + p {
  margin-top: 5px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 18px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fff5f5f5", endColorstr= "#fff9f9f9", GradientType= 0);
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-color: #f7f7f7;
  background-image: linear-gradient(#f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  border-radius: 4px;
  margin-bottom: 18px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px #0000001a;
}

.progress .bar {
  color: #fff;
  float: left;
  text-align: center;
  text-shadow: 0 -1px #00000040;
  width: 0%;
  height: 100%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff149bdf", endColorstr= "#ff0480be", GradientType= 0);
  box-sizing: border-box;
  -o-transition: width .6s ease;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-color: #0e90d2;
  background-image: linear-gradient(#149bdf, #0480be);
  background-repeat: repeat-x;
  font-size: 12px;
  transition: width .6s;
  box-shadow: inset 0 -1px #00000026;
}

.progress .bar + .bar {
  box-shadow: inset 1px 0 #00000026, inset 0 -1px #00000026;
}

.progress-striped .bar {
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-color: #149bdf;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

.progress.active .bar {
  -ms-animation: progress-bar-stripes 2s linear infinite;
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-danger .bar, .progress .bar-danger {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffee5f5b", endColorstr= "#ffc43c35", GradientType= 0);
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-color: #dd514c;
  background-image: linear-gradient(#ee5f5b, #c43c35);
  background-repeat: repeat-x;
}

.progress-danger.progress-striped .bar, .progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-success .bar, .progress .bar-success {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff62c462", endColorstr= "#ff57a957", GradientType= 0);
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-color: #5eb95e;
  background-image: linear-gradient(#62c462, #57a957);
  background-repeat: repeat-x;
}

.progress-success.progress-striped .bar, .progress-striped .bar-success {
  background-color: #62c462;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-info .bar, .progress .bar-info {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff5bc0de", endColorstr= "#ff339bb9", GradientType= 0);
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-color: #4bb1cf;
  background-image: linear-gradient(#5bc0de, #339bb9);
  background-repeat: repeat-x;
}

.progress-info.progress-striped .bar, .progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-warning .bar, .progress .bar-warning {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fffbb450", endColorstr= "#fff89406", GradientType= 0);
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-color: #faa732;
  background-image: linear-gradient(#fbb450, #f89406);
  background-repeat: repeat-x;
}

.progress-warning.progress-striped .bar, .progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.hero-unit {
  background-color: #eee;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 60px;
}

.hero-unit h1 {
  color: inherit;
  letter-spacing: -1px;
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
}

.hero-unit p {
  color: inherit;
  font-size: 18px;
  font-weight: 200;
  line-height: 27px;
}

.tooltip {
  z-index: 1030;
  visibility: visible;
  opacity: 0;
  filter: alpha(opacity= 0);
  padding: 5px;
  font-size: 11px;
  display: block;
  position: absolute;
}

.tooltip.in {
  opacity: .8;
  filter: alpha(opacity= 80);
}

.tooltip.top {
  margin-top: -3px;
}

.tooltip.right {
  margin-left: 3px;
}

.tooltip.bottom {
  margin-top: 3px;
}

.tooltip.left {
  margin-left: -3px;
}

.tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  max-width: 200px;
  padding: 3px 8px;
  text-decoration: none;
}

.tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.popover {
  z-index: 1010;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  width: 236px;
  padding: 1px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px #0003;
}

.popover.top {
  margin-bottom: 10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-right: 10px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

.popover-content {
  padding: 9px 14px;
}

.popover-content p, .popover-content ul, .popover-content ol {
  margin-bottom: 0;
}

.popover .arrow, .popover .arrow:after {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
}

.popover .arrow:after {
  content: "";
  z-index: -1;
}

.popover.top .arrow {
  border-width: 10px 10px 0;
  border-top-color: #fff;
  margin-left: -10px;
  bottom: -10px;
  left: 50%;
}

.popover.top .arrow:after {
  border-width: 11px 11px 0;
  border-top-color: #00000040;
  bottom: -1px;
  left: -11px;
}

.popover.right .arrow {
  border-width: 10px 10px 10px 0;
  border-right-color: #fff;
  margin-top: -10px;
  top: 50%;
  left: -10px;
}

.popover.right .arrow:after {
  border-width: 11px 11px 11px 0;
  border-right-color: #00000040;
  bottom: -11px;
  left: -1px;
}

.popover.bottom .arrow {
  border-width: 0 10px 10px;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: -10px;
  left: 50%;
}

.popover.bottom .arrow:after {
  border-width: 0 11px 11px;
  border-bottom-color: #00000040;
  top: -1px;
  left: -11px;
}

.popover.left .arrow {
  border-width: 10px 0 10px 10px;
  border-left-color: #fff;
  margin-top: -10px;
  top: 50%;
  right: -10px;
}

.popover.left .arrow:after {
  border-width: 11px 0 11px 11px;
  border-left-color: #00000040;
  bottom: -11px;
  right: -1px;
}

.modal-open .dropdown-menu {
  z-index: 2050;
}

.modal-open .popover {
  z-index: 2060;
}

.modal-open .tooltip {
  z-index: 2080;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  inset: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop, .modal-backdrop.fade.in {
  opacity: .8;
  filter: alpha(opacity= 80);
}

.modal {
  z-index: 1050;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #0000004d;
  border-radius: 6px;
  width: 560px;
  margin: -250px 0 0 -280px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  box-shadow: 0 3px 7px #0000004d;
}

.modal.fade {
  -o-transition: opacity .3s linear, top .3s ease-out;
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}

.modal.fade.in {
  top: 50%;
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding: 9px 15px;
}

.modal-header .close {
  margin-top: 2px;
}

.modal-header h3 {
  margin: 0;
  line-height: 30px;
}

.modal-body {
  max-height: 400px;
  padding: 15px;
  overflow-y: auto;
}

.modal-form {
  margin-bottom: 0;
}

.modal-footer {
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-radius: 0 0 6px 6px;
  margin-bottom: 0;
  padding: 14px 15px 15px;
  box-shadow: inset 0 1px #fff;
}

.modal-footer:before, .modal-footer:after {
  content: "";
  line-height: 0;
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:active, .open .dropdown-toggle {
  outline: 0;
}

.caret {
  vertical-align: top;
  content: "";
  border-top: 4px solid #000;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  width: 0;
  height: 0;
  display: inline-block;
}

.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 5px 10px #0003;
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu .divider {
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
  height: 1px;
  margin: 8px 1px;
  overflow: hidden;
}

.dropdown-menu a {
  clear: both;
  color: #333;
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 18px;
  display: block;
}

.dropdown-menu li > a:hover, .dropdown-menu li > a:focus, .dropdown-submenu:hover > a {
  color: #fff;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff0088cc", endColorstr= "#ff0077b3", GradientType= 0);
  background-image: -moz-linear-gradient(top, #08c, #0077b3);
  background-color: #0081c2;
  background-image: linear-gradient(#08c, #0077b3);
  background-repeat: repeat-x;
  text-decoration: none;
}

.dropdown-menu .active > a, .dropdown-menu .active > a:hover {
  color: #fff;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff0088cc", endColorstr= "#ff0077b3", GradientType= 0);
  background-image: -moz-linear-gradient(top, #08c, #0077b3);
  background-color: #0081c2;
  background-image: linear-gradient(#08c, #0077b3);
  background-repeat: repeat-x;
  outline: 0;
  text-decoration: none;
}

.dropdown-menu .disabled > a, .dropdown-menu .disabled > a:hover {
  color: #999;
}

.dropdown-menu .disabled > a:hover {
  cursor: default;
  background-color: #0000;
  text-decoration: none;
}

.open > .dropdown-menu {
  display: block;
}

.pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "↑";
  border-top: 0;
  border-bottom: 4px solid #000;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 1px;
  top: auto;
  bottom: 100%;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  border-radius: 0 6px 6px;
  margin-top: -6px;
  margin-left: -1px;
  top: 0;
  left: 100%;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  content: " ";
  float: right;
  border: 5px solid #0000;
  border-left-color: #ccc;
  border-right-width: 0;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  display: block;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}

.typeahead {
  border-radius: 4px;
  margin-top: 2px;
}

.accordion {
  margin-bottom: 18px;
}

.accordion-group {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 2px;
}

.accordion-heading {
  border-bottom: 0;
}

.accordion-heading .accordion-toggle {
  padding: 8px 15px;
  display: block;
}

.accordion-toggle {
  cursor: pointer;
}

.accordion-inner {
  border-top: 1px solid #e5e5e5;
  padding: 9px 15px;
}

.carousel {
  margin-bottom: 18px;
  line-height: 1;
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel .item {
  -o-transition: .6s ease-in-out left;
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel .item > img {
  line-height: 1;
  display: block;
}

.carousel .active, .carousel .next, .carousel .prev {
  display: block;
}

.carousel .active {
  left: 0;
}

.carousel .next, .carousel .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel .next {
  left: 100%;
}

.carousel .prev {
  left: -100%;
}

.carousel .next.left, .carousel .prev.right {
  left: 0;
}

.carousel .active.left {
  left: -100%;
}

.carousel .active.right {
  left: 100%;
}

.carousel-control {
  color: #fff;
  text-align: center;
  opacity: .5;
  width: 40px;
  height: 40px;
  filter: alpha(opacity= 50);
  background: #222;
  border: 3px solid #fff;
  border-radius: 23px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  position: absolute;
  top: 40%;
  left: 15px;
}

.carousel-control.right {
  left: auto;
  right: 15px;
}

.carousel-control:hover {
  color: #fff;
  opacity: .9;
  filter: alpha(opacity= 90);
  text-decoration: none;
}

.carousel-caption {
  background: #000000bf;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-caption h4, .carousel-caption p {
  color: #fff;
  line-height: 18px;
}

.carousel-caption h4 {
  margin: 0 0 5px;
}

.carousel-caption p {
  margin-bottom: 0;
}

.well {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-height: 20px;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px #0000000d;
}

.well blockquote {
  border-color: #00000026;
}

.well-large {
  border-radius: 6px;
  padding: 24px;
}

.well-small {
  border-radius: 3px;
  padding: 9px;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .2;
  filter: alpha(opacity= 20);
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
}

.close:hover {
  color: #000;
  cursor: pointer;
  opacity: .4;
  filter: alpha(opacity= 40);
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.affix {
  position: fixed;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  height: 0;
  overflow: hidden;
  overflow: visible \9 ;
  -o-transition: height .35s ease;
  transition: height .35s;
  position: relative;
}

.collapse.in {
  height: auto;
}

.hidden {
  visibility: hidden;
  display: none;
}

.visible-phone, .visible-tablet, .hidden-desktop {
  display: none !important;
}

.visible-desktop {
  display: inherit !important;
}

@media (width >= 768px) and (width <= 979px) {
  .hidden-desktop {
    display: inherit !important;
  }

  .visible-desktop {
    display: none !important;
  }

  .visible-tablet {
    display: inherit !important;
  }

  .hidden-tablet {
    display: none !important;
  }
}

@media (width <= 767px) {
  .hidden-desktop {
    display: inherit !important;
  }

  .visible-desktop {
    display: none !important;
  }

  .visible-phone {
    display: inherit !important;
  }

  .hidden-phone {
    display: none !important;
  }

  body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar-fixed-top, .navbar-fixed-bottom {
    margin-left: -20px;
    margin-right: -20px;
  }

  .container-fluid {
    padding: 0;
  }

  .dl-horizontal dt {
    float: none;
    clear: none;
    text-align: left;
    width: auto;
  }

  .dl-horizontal dd {
    margin-left: 0;
  }

  .container {
    width: auto;
  }

  .row-fluid {
    width: 100%;
  }

  .row, .thumbnails {
    margin-left: 0;
  }

  .thumbnails > li {
    float: none;
    margin-left: 0;
  }

  [class*="span"], .row-fluid [class*="span"] {
    float: none;
    width: auto;
    margin-left: 0;
    display: block;
  }

  .span12, .row-fluid .span12 {
    box-sizing: border-box;
    width: 100%;
  }

  .input-large, .input-xlarge, .input-xxlarge, input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input {
    box-sizing: border-box;
    width: 100%;
    min-height: 30px;
    display: block;
  }

  .input-prepend input, .input-append input, .input-prepend input[class*="span"], .input-append input[class*="span"] {
    width: auto;
    display: inline-block;
  }

  .modal {
    width: auto;
    margin: 0;
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
  }

  .modal.fade.in {
    top: auto;
  }
}

@media (width <= 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .page-header h1 small {
    line-height: 18px;
    display: block;
  }

  input[type="checkbox"], input[type="radio"] {
    border: 1px solid #ccc;
  }

  .form-horizontal .control-group > label {
    float: none;
    text-align: left;
    width: auto;
    padding-top: 0;
  }

  .form-horizontal .controls {
    margin-left: 0;
  }

  .form-horizontal .control-list {
    padding-top: 0;
  }

  .form-horizontal .form-actions {
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal {
    top: 10px;
    left: 10px;
    right: 10px;
  }

  .modal-header .close {
    margin: -10px;
    padding: 10px;
  }

  .carousel-caption {
    position: static;
  }
}

@media (width >= 768px) and (width <= 979px) {
  .row {
    margin-left: -20px;
  }

  .row:before, .row:after {
    content: "";
    line-height: 0;
    display: table;
  }

  .row:after {
    clear: both;
  }

  [class*="span"] {
    float: left;
    margin-left: 20px;
  }

  .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container, .span12 {
    width: 724px;
  }

  .span11 {
    width: 662px;
  }

  .span10 {
    width: 600px;
  }

  .span9 {
    width: 538px;
  }

  .span8 {
    width: 476px;
  }

  .span7 {
    width: 414px;
  }

  .span6 {
    width: 352px;
  }

  .span5 {
    width: 290px;
  }

  .span4 {
    width: 228px;
  }

  .span3 {
    width: 166px;
  }

  .span2 {
    width: 104px;
  }

  .span1 {
    width: 42px;
  }

  .offset12 {
    margin-left: 764px;
  }

  .offset11 {
    margin-left: 702px;
  }

  .offset10 {
    margin-left: 640px;
  }

  .offset9 {
    margin-left: 578px;
  }

  .offset8 {
    margin-left: 516px;
  }

  .offset7 {
    margin-left: 454px;
  }

  .offset6 {
    margin-left: 392px;
  }

  .offset5 {
    margin-left: 330px;
  }

  .offset4 {
    margin-left: 268px;
  }

  .offset3 {
    margin-left: 206px;
  }

  .offset2 {
    margin-left: 144px;
  }

  .offset1 {
    margin-left: 82px;
  }

  .row-fluid {
    width: 100%;
  }

  .row-fluid:before, .row-fluid:after {
    content: "";
    line-height: 0;
    display: table;
  }

  .row-fluid:after {
    clear: both;
  }

  .row-fluid [class*="span"] {
    box-sizing: border-box;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.76243%;
    display: block;
  }

  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }

  .row-fluid .span12 {
    width: 100%;
  }

  .row-fluid .span11 {
    width: 91.4365%;
  }

  .row-fluid .span10 {
    width: 82.8729%;
  }

  .row-fluid .span9 {
    width: 74.3094%;
  }

  .row-fluid .span8 {
    width: 65.7459%;
  }

  .row-fluid .span7 {
    width: 57.1823%;
  }

  .row-fluid .span6 {
    width: 48.6188%;
  }

  .row-fluid .span5 {
    width: 40.0553%;
  }

  .row-fluid .span4 {
    width: 31.4917%;
  }

  .row-fluid .span3 {
    width: 22.9282%;
  }

  .row-fluid .span2 {
    width: 14.3646%;
  }

  .row-fluid .span1 {
    width: 5.80111%;
  }

  .row-fluid .offset12 {
    margin-left: 105.525%;
  }

  .row-fluid .offset12:first-child {
    margin-left: 102.762%;
  }

  .row-fluid .offset11 {
    margin-left: 96.9613%;
  }

  .row-fluid .offset11:first-child {
    margin-left: 94.1989%;
  }

  .row-fluid .offset10 {
    margin-left: 88.3978%;
  }

  .row-fluid .offset10:first-child {
    margin-left: 85.6354%;
  }

  .row-fluid .offset9 {
    margin-left: 79.8343%;
  }

  .row-fluid .offset9:first-child {
    margin-left: 77.0718%;
  }

  .row-fluid .offset8 {
    margin-left: 71.2707%;
  }

  .row-fluid .offset8:first-child {
    margin-left: 68.5083%;
  }

  .row-fluid .offset7 {
    margin-left: 62.7072%;
  }

  .row-fluid .offset7:first-child {
    margin-left: 59.9447%;
  }

  .row-fluid .offset6 {
    margin-left: 54.1437%;
  }

  .row-fluid .offset6:first-child {
    margin-left: 51.3812%;
  }

  .row-fluid .offset5 {
    margin-left: 45.5801%;
  }

  .row-fluid .offset5:first-child {
    margin-left: 42.8177%;
  }

  .row-fluid .offset4 {
    margin-left: 37.0166%;
  }

  .row-fluid .offset4:first-child {
    margin-left: 34.2541%;
  }

  .row-fluid .offset3 {
    margin-left: 28.453%;
  }

  .row-fluid .offset3:first-child {
    margin-left: 25.6906%;
  }

  .row-fluid .offset2 {
    margin-left: 19.8895%;
  }

  .row-fluid .offset2:first-child {
    margin-left: 17.1271%;
  }

  .row-fluid .offset1 {
    margin-left: 11.326%;
  }

  .row-fluid .offset1:first-child {
    margin-left: 8.56354%;
  }

  input, textarea, .uneditable-input {
    margin-left: 0;
  }

  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px;
  }

  input.span12, textarea.span12, .uneditable-input.span12 {
    width: 710px;
  }

  input.span11, textarea.span11, .uneditable-input.span11 {
    width: 648px;
  }

  input.span10, textarea.span10, .uneditable-input.span10 {
    width: 586px;
  }

  input.span9, textarea.span9, .uneditable-input.span9 {
    width: 524px;
  }

  input.span8, textarea.span8, .uneditable-input.span8 {
    width: 462px;
  }

  input.span7, textarea.span7, .uneditable-input.span7 {
    width: 400px;
  }

  input.span6, textarea.span6, .uneditable-input.span6 {
    width: 338px;
  }

  input.span5, textarea.span5, .uneditable-input.span5 {
    width: 276px;
  }

  input.span4, textarea.span4, .uneditable-input.span4 {
    width: 214px;
  }

  input.span3, textarea.span3, .uneditable-input.span3 {
    width: 152px;
  }

  input.span2, textarea.span2, .uneditable-input.span2 {
    width: 90px;
  }

  input.span1, textarea.span1, .uneditable-input.span1 {
    width: 28px;
  }
}

@media (width >= 1200px) {
  .row {
    margin-left: -30px;
  }

  .row:before, .row:after {
    content: "";
    line-height: 0;
    display: table;
  }

  .row:after {
    clear: both;
  }

  [class*="span"] {
    float: left;
    margin-left: 30px;
  }

  .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container, .span12 {
    width: 1170px;
  }

  .span11 {
    width: 1070px;
  }

  .span10 {
    width: 970px;
  }

  .span9 {
    width: 870px;
  }

  .span8 {
    width: 770px;
  }

  .span7 {
    width: 670px;
  }

  .span6 {
    width: 570px;
  }

  .span5 {
    width: 470px;
  }

  .span4 {
    width: 370px;
  }

  .span3 {
    width: 270px;
  }

  .span2 {
    width: 170px;
  }

  .span1 {
    width: 70px;
  }

  .offset12 {
    margin-left: 1230px;
  }

  .offset11 {
    margin-left: 1130px;
  }

  .offset10 {
    margin-left: 1030px;
  }

  .offset9 {
    margin-left: 930px;
  }

  .offset8 {
    margin-left: 830px;
  }

  .offset7 {
    margin-left: 730px;
  }

  .offset6 {
    margin-left: 630px;
  }

  .offset5 {
    margin-left: 530px;
  }

  .offset4 {
    margin-left: 430px;
  }

  .offset3 {
    margin-left: 330px;
  }

  .offset2 {
    margin-left: 230px;
  }

  .offset1 {
    margin-left: 130px;
  }

  .row-fluid {
    width: 100%;
  }

  .row-fluid:before, .row-fluid:after {
    content: "";
    line-height: 0;
    display: table;
  }

  .row-fluid:after {
    clear: both;
  }

  .row-fluid [class*="span"] {
    box-sizing: border-box;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.5641%;
    display: block;
  }

  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }

  .row-fluid .span12 {
    width: 100%;
  }

  .row-fluid .span11 {
    width: 91.453%;
  }

  .row-fluid .span10 {
    width: 82.906%;
  }

  .row-fluid .span9 {
    width: 74.359%;
  }

  .row-fluid .span8 {
    width: 65.812%;
  }

  .row-fluid .span7 {
    width: 57.265%;
  }

  .row-fluid .span6 {
    width: 48.718%;
  }

  .row-fluid .span5 {
    width: 40.1709%;
  }

  .row-fluid .span4 {
    width: 31.6239%;
  }

  .row-fluid .span3 {
    width: 23.0769%;
  }

  .row-fluid .span2 {
    width: 14.5299%;
  }

  .row-fluid .span1 {
    width: 5.98291%;
  }

  .row-fluid .offset12 {
    margin-left: 105.128%;
  }

  .row-fluid .offset12:first-child {
    margin-left: 102.564%;
  }

  .row-fluid .offset11 {
    margin-left: 96.5812%;
  }

  .row-fluid .offset11:first-child {
    margin-left: 94.0171%;
  }

  .row-fluid .offset10 {
    margin-left: 88.0342%;
  }

  .row-fluid .offset10:first-child {
    margin-left: 85.4701%;
  }

  .row-fluid .offset9 {
    margin-left: 79.4872%;
  }

  .row-fluid .offset9:first-child {
    margin-left: 76.9231%;
  }

  .row-fluid .offset8 {
    margin-left: 70.9402%;
  }

  .row-fluid .offset8:first-child {
    margin-left: 68.3761%;
  }

  .row-fluid .offset7 {
    margin-left: 62.3932%;
  }

  .row-fluid .offset7:first-child {
    margin-left: 59.8291%;
  }

  .row-fluid .offset6 {
    margin-left: 53.8462%;
  }

  .row-fluid .offset6:first-child {
    margin-left: 51.2821%;
  }

  .row-fluid .offset5 {
    margin-left: 45.2991%;
  }

  .row-fluid .offset5:first-child {
    margin-left: 42.735%;
  }

  .row-fluid .offset4 {
    margin-left: 36.7521%;
  }

  .row-fluid .offset4:first-child {
    margin-left: 34.188%;
  }

  .row-fluid .offset3 {
    margin-left: 28.2051%;
  }

  .row-fluid .offset3:first-child {
    margin-left: 25.641%;
  }

  .row-fluid .offset2 {
    margin-left: 19.6581%;
  }

  .row-fluid .offset2:first-child {
    margin-left: 17.094%;
  }

  .row-fluid .offset1 {
    margin-left: 11.1111%;
  }

  .row-fluid .offset1:first-child {
    margin-left: 8.54701%;
  }

  input, textarea, .uneditable-input {
    margin-left: 0;
  }

  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 30px;
  }

  input.span12, textarea.span12, .uneditable-input.span12 {
    width: 1156px;
  }

  input.span11, textarea.span11, .uneditable-input.span11 {
    width: 1056px;
  }

  input.span10, textarea.span10, .uneditable-input.span10 {
    width: 956px;
  }

  input.span9, textarea.span9, .uneditable-input.span9 {
    width: 856px;
  }

  input.span8, textarea.span8, .uneditable-input.span8 {
    width: 756px;
  }

  input.span7, textarea.span7, .uneditable-input.span7 {
    width: 656px;
  }

  input.span6, textarea.span6, .uneditable-input.span6 {
    width: 556px;
  }

  input.span5, textarea.span5, .uneditable-input.span5 {
    width: 456px;
  }

  input.span4, textarea.span4, .uneditable-input.span4 {
    width: 356px;
  }

  input.span3, textarea.span3, .uneditable-input.span3 {
    width: 256px;
  }

  input.span2, textarea.span2, .uneditable-input.span2 {
    width: 156px;
  }

  input.span1, textarea.span1, .uneditable-input.span1 {
    width: 56px;
  }

  .thumbnails {
    margin-left: -30px;
  }

  .thumbnails > li {
    margin-left: 30px;
  }

  .row-fluid .thumbnails {
    margin-left: 0;
  }
}

@media (width <= 979px) {
  body {
    padding-top: 0;
  }

  .navbar-fixed-top, .navbar-fixed-bottom {
    position: static;
  }

  .navbar-fixed-top {
    margin-bottom: 18px;
  }

  .navbar-fixed-bottom {
    margin-top: 18px;
  }

  .navbar-fixed-top .navbar-inner, .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }

  .navbar .container {
    width: auto;
    padding: 0;
  }

  .navbar .brand {
    margin: 0 0 0 -5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav-collapse {
    clear: both;
  }

  .nav-collapse .nav {
    float: none;
    margin: 0 0 9px;
  }

  .nav-collapse .nav > li {
    float: none;
  }

  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > .divider-vertical {
    display: none;
  }

  .nav-collapse .nav .nav-header {
    color: #555;
    text-shadow: none;
  }

  .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a {
    color: #555;
    border-radius: 3px;
    padding: 9px 15px;
    font-weight: bold;
  }

  .nav-collapse .btn {
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: normal;
  }

  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }

  .nav-collapse .nav > li > a:hover, .nav-collapse .dropdown-menu a:hover {
    background-color: #f2f2f2;
  }

  .navbar-inverse .nav-collapse .nav > li > a:hover, .navbar-inverse .nav-collapse .dropdown-menu a:hover {
    background-color: #111;
  }

  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }

  .nav-collapse .dropdown-menu {
    float: none;
    max-width: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #0000;
    border: none;
    border-radius: 0;
    margin: 0 15px;
    padding: 0;
    display: block;
    position: static;
    top: auto;
    left: auto;
  }

  .nav-collapse .dropdown-menu:before, .nav-collapse .dropdown-menu:after, .nav-collapse .dropdown-menu .divider {
    display: none;
  }

  .nav-collapse .navbar-form, .nav-collapse .navbar-search {
    float: none;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin: 9px 0;
    padding: 9px 15px;
    box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff1a;
  }

  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }

  .nav-collapse, .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }

  .navbar .btn-navbar {
    display: block;
  }

  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (width >= 980px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.0afaa153.eot");
  src: url("fontawesome-webfont.0afaa153.eot#iefix") format("eot"), url("fontawesome-webfont.ae210c42.woff") format("woff"), url("fontawesome-webfont.e84136df.ttf") format("truetype"), url("fontawesome-webfont.6c37b5ff.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
}

a [class^="icon-"], a [class*=" icon-"] {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  display: inline-block;
}

.icon-large:before {
  vertical-align: top;
  font-size: 1.33333em;
}

.btn [class^="icon-"], .btn [class*=" icon-"] {
  line-height: .9em;
}

li [class^="icon-"], li [class*=" icon-"] {
  text-align: center;
  width: 1.25em;
  display: inline-block;
}

li .icon-large[class^="icon-"], li .icon-large[class*=" icon-"] {
  width: 1.875em;
}

li[class^="icon-"], li[class*=" icon-"] {
  margin-left: 0;
  list-style-type: none;
}

li[class^="icon-"]:before, li[class*=" icon-"]:before {
  text-indent: -2em;
  text-align: center;
}

li[class^="icon-"].icon-large:before, li[class*=" icon-"].icon-large:before {
  text-indent: -1.33333em;
}

.icon-glass:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-th-large:before {
  content: "";
}

.icon-th:before {
  content: "";
}

.icon-th-list:before {
  content: "";
}

.icon-ok:before {
  content: "";
}

.icon-remove:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.icon-off:before {
  content: "";
}

.icon-signal:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-time:before {
  content: "";
}

.icon-road:before {
  content: "";
}

.icon-download-alt:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-play-circle:before {
  content: "";
}

.icon-repeat:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-list-alt:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-volume-off:before {
  content: "";
}

.icon-volume-down:before {
  content: "";
}

.icon-volume-up:before {
  content: "";
}

.icon-qrcode:before {
  content: "";
}

.icon-barcode:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-tags:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-font:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-text-height:before {
  content: "";
}

.icon-text-width:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-center:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-align-justify:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-indent-left:before {
  content: "";
}

.icon-indent-right:before {
  content: "";
}

.icon-facetime-video:before {
  content: "";
}

.icon-picture:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-map-marker:before {
  content: "";
}

.icon-adjust:before {
  content: "";
}

.icon-tint:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-step-backward:before {
  content: "";
}

.icon-fast-backward:before {
  content: "";
}

.icon-backward:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-forward:before {
  content: "";
}

.icon-fast-forward:before {
  content: "";
}

.icon-step-forward:before {
  content: "";
}

.icon-eject:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-plus-sign:before {
  content: "";
}

.icon-minus-sign:before {
  content: "";
}

.icon-remove-sign:before {
  content: "";
}

.icon-ok-sign:before {
  content: "";
}

.icon-question-sign:before {
  content: "";
}

.icon-info-sign:before {
  content: "";
}

.icon-screenshot:before {
  content: "";
}

.icon-remove-circle:before {
  content: "";
}

.icon-ok-circle:before {
  content: "";
}

.icon-ban-circle:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-share-alt:before {
  content: "";
}

.icon-resize-full:before {
  content: "";
}

.icon-resize-small:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-asterisk:before {
  content: "";
}

.icon-exclamation-sign:before {
  content: "";
}

.icon-gift:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-eye-open:before {
  content: "";
}

.icon-eye-close:before {
  content: "";
}

.icon-warning-sign:before {
  content: "";
}

.icon-plane:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-random:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-magnet:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-retweet:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-folder-close:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-resize-vertical:before {
  content: "";
}

.icon-resize-horizontal:before {
  content: "";
}

.icon-bar-chart:before {
  content: "";
}

.icon-twitter-sign:before {
  content: "";
}

.icon-facebook-sign:before {
  content: "";
}

.icon-camera-retro:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-cogs:before {
  content: "";
}

.icon-comments:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-heart-empty:before {
  content: "";
}

.icon-signout:before {
  content: "";
}

.icon-linkedin-sign:before {
  content: "";
}

.icon-pushpin:before {
  content: "";
}

.icon-external-link:before {
  content: "";
}

.icon-signin:before {
  content: "";
}

.icon-trophy:before {
  content: "";
}

.icon-github-sign:before {
  content: "";
}

.icon-upload-alt:before {
  content: "";
}

.icon-lemon:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-check-empty:before {
  content: "";
}

.icon-bookmark-empty:before {
  content: "";
}

.icon-phone-sign:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-hdd:before {
  content: "";
}

.icon-bullhorn:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-certificate:before {
  content: "";
}

.icon-hand-right:before {
  content: "";
}

.icon-hand-left:before {
  content: "";
}

.icon-hand-up:before {
  content: "";
}

.icon-hand-down:before {
  content: "";
}

.icon-circle-arrow-left:before {
  content: "";
}

.icon-circle-arrow-right:before {
  content: "";
}

.icon-circle-arrow-up:before {
  content: "";
}

.icon-circle-arrow-down:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-tasks:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-fullscreen:before {
  content: "";
}

.icon-group:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-beaker:before {
  content: "";
}

.icon-cut:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-paper-clip:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-sign-blank:before {
  content: "";
}

.icon-reorder:before {
  content: "";
}

.icon-list-ul:before {
  content: "";
}

.icon-list-ol:before {
  content: "";
}

.icon-strikethrough:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-table:before {
  content: "";
}

.icon-magic:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-pinterest-sign:before {
  content: "";
}

.icon-google-plus-sign:before {
  content: "";
}

.icon-google-plus:before {
  content: "";
}

.icon-money:before {
  content: "";
}

.icon-caret-down:before {
  content: "";
}

.icon-caret-up:before {
  content: "";
}

.icon-caret-left:before {
  content: "";
}

.icon-caret-right:before {
  content: "";
}

.icon-columns:before {
  content: "";
}

.icon-sort:before {
  content: "";
}

.icon-sort-down:before {
  content: "";
}

.icon-sort-up:before {
  content: "";
}

.icon-envelope-alt:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-undo:before {
  content: "";
}

.icon-legal:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-comment-alt:before {
  content: "";
}

.icon-comments-alt:before {
  content: "";
}

.icon-bolt:before {
  content: "";
}

.icon-sitemap:before {
  content: "";
}

.icon-umbrella:before {
  content: "";
}

.icon-paste:before {
  content: "";
}

.icon-user-md:before {
  content: "";
}

body, html {
  background: #000;
  width: 100%;
  height: 100%;
}

a {
  outline: none !important;
}

.modal-body {
  max-height: 450px;
}

noscript > div {
  z-index: 999999;
  background: #000c;
  width: 100%;
  height: 100%;
  position: fixed;
}

noscript p {
  color: #fff;
  text-shadow: 3px 3px 5px #000;
  margin-top: -21px;
  margin-left: -290px;
  font-size: 35px;
  position: fixed;
  top: 50%;
  left: 50%;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

::-webkit-scrollbar-thumb:active {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #666;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666;
}

::-webkit-scrollbar-track:active {
  background: #333;
}

::-webkit-scrollbar-corner {
  background: none;
}

header {
  background: #000;
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
}

header #nav-left {
  float: left;
  cursor: default;
  width: 300px;
}

header #nav-left a {
  height: 27px;
  padding: 10px 15px;
  display: inline-block;
}

header #nav-left a:first-child {
  margin-left: 10px;
}

header #nav-left a img {
  opacity: .4;
  -o-transition: opacity .4s ease-in-out;
  transition: opacity .4s ease-in-out;
}

header #nav-left a:hover img, header #nav-left a.active img {
  opacity: .8;
}

header #nav-left a.active {
  background: url("menu-highlight.7da7d629.png") bottom no-repeat;
}

header #nav-center {
  text-align: center;
  cursor: default;
  width: 300px;
  margin: 0 auto;
}

header #nav-center i {
  color: #fff;
  opacity: .4;
  -o-transition: opacity .4s ease-in-out;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  transition: opacity .4s ease-in-out;
}

header #nav-center a {
  width: 48px;
  height: 33px;
  padding-top: 15px;
  display: inline-block;
}

header #nav-center a:hover {
  text-decoration: none;
}

header #nav-center a:hover i {
  opacity: 1;
  text-decoration: none;
}

header #nav-right {
  color: #fff;
  max-width: 35%;
  position: absolute;
  top: 15px;
  right: 15px;
}

header #nav-right > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#overlay {
  opacity: .8;
  z-index: 50;
  background: url("overlay-full.a413f3e2.png");
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

#container {
  width: 100%;
  position: absolute;
  top: 48px;
  bottom: 0;
  overflow: hidden;
}

#player {
  width: 100%;
  height: 100%;
}

#pop-up-container {
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  overflow-y: auto;
}

body > .tooltip {
  pointer-events: none;
}

#episode-container {
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

#specials, #episodes {
  clear: both;
  padding: 50px 0 25px;
}

#episodes h2 {
  color: #fff;
  border-bottom: 3px solid #33b5e5;
  margin-top: 40px;
  margin-bottom: 40px;
  font: 150px RobotoThin, Arial, sans-serif;
}

#episodes h2 span {
  background: #33b5e580;
}

#specials p, #episodes p {
  color: #fff;
  float: left;
  width: 20%;
  min-width: 165px;
}

#specials a, #episodes a {
  color: #fff;
}

#specials a:hover, #episodes a:hover {
  color: #fff;
  text-decoration: underline;
}

.nav li.disabled {
  pointer-events: none;
  opacity: .4;
}

#year-dropdown ul.dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}

#now-playing-info {
  color: #fff;
  z-index: 5;
  text-shadow: 2px 2px 2px #000;
  filter: dropshadow(color= #000, offx= 2, offy= 2);
  background: none;
  font: 27px RobotoCondensed, Arial, sans-serif;
  position: absolute;
  bottom: 5%;
  left: 5%;
}
/*# sourceMappingURL=index.71997d6b.css.map */
