/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 12, 2012 */



@font-face {
    font-family: 'AndroidClock-LargeRegular';
    src: url('androidclock-webfont.eot');
    src: url('androidclock-webfont.eot?#iefix') format('embedded-opentype'),
         url('androidclock-webfont.woff') format('woff'),
         url('androidclock-webfont.ttf') format('truetype'),
         url('androidclock-webfont.svg#AndroidClock-LargeRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'AndroidClock-LargeRegular';
    src: url('androidclock_highlight-webfont.eot');
    src: url('androidclock_highlight-webfont.eot?#iefix') format('embedded-opentype'),
         url('androidclock_highlight-webfont.woff') format('woff'),
         url('androidclock_highlight-webfont.ttf') format('truetype'),
         url('androidclock_highlight-webfont.svg#AndroidClock-LargeRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'AndroidClock-LargeRegular';
    src: url('androidclock_solid-webfont.eot');
    src: url('androidclock_solid-webfont.eot?#iefix') format('embedded-opentype'),
         url('androidclock_solid-webfont.woff') format('woff'),
         url('androidclock_solid-webfont.ttf') format('truetype'),
         url('androidclock_solid-webfont.svg#AndroidClock-LargeRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}