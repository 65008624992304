@import "../font/roboto/stylesheet.css";
@import "../font/androidclock/stylesheet.css";
@import "../lib/twitter-bootstrap/css/bootstrap.css";
@import "../lib/font-awesome/css/font-awesome.css";

body,
html {
  width     : 100%;
  height    : 100%;
  background: black;
}

a {
  outline: none !important;
}

.modal-body {
  max-height: 450px;
}

/* =================================================================== */
/* ===== NOSCRIPT ==================================================== */
noscript>div {
  position  : fixed;
  height    : 100%;
  width     : 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index   : 999999;
}

noscript p {
  color      : white;
  text-shadow: 3px 3px 5px #000000;
  font-size  : 35px;
  top        : 50%;
  left       : 50%;
  position   : fixed;
  margin-top : -21px;
  margin-left: -290px;
}

/* =================================================================== */
/* ===== SCROLLBAR =================================================== */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* ================================================================= */
/* ===== HEADER ==================================================== */

header {
  height    : 48px;
  width     : 100%;
  background: black;
  position  : absolute;
  top       : 0;
}

header #nav-left {
  width : 300px;
  float : left;
  cursor: default;
}

header #nav-left a {
  padding-top   : 10px;
  padding-bottom: 10px;
  padding-right : 15px;
  padding-left  : 15px;

  height : 27px;
  display: inline-block;
}

header #nav-left a:first-child {
  margin-left: 10px;
}

header #nav-left a img {
  opacity: 0.4;

  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition   : opacity 0.4s ease-in-out;
  -o-transition     : opacity 0.4s ease-in-out;
  transition        : opacity 0.4s ease-in-out;
}

header #nav-left a:hover img,
header #nav-left a.active img {
  opacity: 0.8;
}

header #nav-left a.active {
  background: url('../img/menu-highlight.png') no-repeat bottom center;
}

header #nav-center {
  margin    : 0 auto;
  width     : 300px;
  text-align: center;
  cursor    : default;
}

header #nav-center i {
  color       : white;
  opacity     : 0.4;
  font-size   : 20px;
  margin-left : 15px;
  margin-right: 15px;

  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition   : opacity 0.4s ease-in-out;
  -o-transition     : opacity 0.4s ease-in-out;
  transition        : opacity 0.4s ease-in-out;
}

header #nav-center a {
  height     : 33px;
  width      : 48px;
  display    : inline-block;
  padding-top: 15px;
}

header #nav-center a:hover {
  text-decoration: none;
}

header #nav-center a:hover i {
  opacity        : 1;
  text-decoration: none;
}

header #nav-right {
  position : absolute;
  right    : 15px;
  top      : 15px;
  color    : white;
  max-width: 35%;
}

header #nav-right>p {
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
}

/* =============================================================== */
/* ===== BODY ==================================================== */

#overlay {
  width     : 100%;
  height    : 100%;
  position  : absolute;
  opacity   : 0.8;
  display   : none;
  background: url('../img/overlay-full.png') repeat;
  z-index   : 50;
}

#container {
  overflow: hidden;
  position: absolute;
  top     : 48px;
  bottom  : 0;
  width   : 100%;
}

#player {
  width : 100%;
  height: 100%;
}

#pop-up-container {
  position  : absolute;
  width     : 100%;
  height    : 100%;
  overflow-y: auto;
  display   : none;
  z-index   : 100;
}

body > .tooltip {
  pointer-events: none;
}

/* =============================================================== */
/* ===== EPISODE/PLAYLIST ======================================== */

#episode-container {
  position: absolute;
  z-index : 1000;
  width   : 100%;
  height  : 100%;
  display : none;
}

#specials,
#episodes {
  padding: 50px 0 25px 0;
  clear     : both;
}

#episodes h2 {
  color        : white;
  font         : 150px 'RobotoThin', Arial, sans-serif;
  border-bottom: 3px solid #33b5e5;
  margin-top   : 40px;
  margin-bottom: 40px;
}

#episodes h2 span {
  background: rgba(51, 181, 229, 0.5);
}

#specials p,
#episodes p {
  color    : white;
  float    : left;
  width    : 20%;
  min-width: 165px;
}

#specials a,
#episodes a {
  color: white;
}

#specials a:hover,
#episodes a:hover {
  color          : white;
  text-decoration: underline;
}

.nav li.disabled {
  pointer-events: none;
  opacity       : 0.4;
}

#year-dropdown ul.dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}

/* ========================================================== */
/* ===== NOW PLAYING ======================================== */

#now-playing-info {
  background: none;
  color     : white;
  font      : 27px 'RobotoCondensed', Arial, sans-serif;

  position: absolute;
  bottom  : 5%;
  left    : 5%;
  z-index : 5;

  text-shadow: 2px 2px 2px #000000;
  filter     : dropshadow(color=#000000, offx=2, offy=2);
}